import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useTransferStock() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectTransferStockStatus());
  const filters = useSelector(Slice.selectors.makeSelectStockTransferFilters());
  return {
    transferStock: (params: Slice.ActionTypes.TransferStock['data']) => {
      // warehouse to driver
      if (filters.givingType === 'warehouse' && filters.receivingType !== 'warehouse') {
        dispatch(
          Slice.actions.transferStock({
            data: {
              ...params,
              action: 'warehouseToDriver',
              givingWarehouseId: filters.givingWarehouseId,
              receivingDriverId: filters.receivingDriverId,
            },
          }),
        );
      }
      // driver to driver
      if (filters.givingType !== 'warehouse' && filters.receivingType !== 'warehouse') {
        dispatch(
          Slice.actions.transferStock({
            data: {
              ...params,
              action: 'driverToDriver',
              givingDriverId: filters.givingDriverId,
              receivingDriverId: filters.receivingDriverId,
            },
          }),
        );
      }
      // driver to warehouse
      if (filters.givingType !== 'warehouse' && filters.receivingType === 'warehouse') {
        dispatch(
          Slice.actions.transferStock({
            data: {
              ...params,
              action: 'driverToWarehouse',
              givingDriverId: filters.givingDriverId,
              receivingWarehouseId: filters.receivingWarehouseId,
            },
          }),
        );
      }
      // warehouse to warehouse
      if (filters.givingType === 'warehouse' && filters.receivingType === 'warehouse') {
        dispatch(
          Slice.actions.transferStock({
            data: {
              ...params,
              action: 'warehouseToWarehouse',
              givingWarehouseId: filters.givingWarehouseId,
              receivingWarehouseId: filters.receivingWarehouseId,
            },
          }),
        );
      }
    },
    isInProgress: status === AsyncStatus.Pending,
  };
}
