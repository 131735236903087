import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AsyncStatus, PA } from 'utlis/State';
import type { SimplifiedStock } from '../Stock';
import { StockExchange } from '../StockExchange';

interface Filters {
  receivingDriverId: string | null;
  givingDriverId: string | null;
  receivingWarehouseId: string | null;
  givingWarehouseId: string | null;
  givingType: string | null;
  receivingType: string | null;
}

export interface State {
  stockTransfer: {
    receivingDriver: {
      data: SimplifiedStock[] | null;
      driverId: string | null;
      status: AsyncStatus;
    };
    receivingDriverPendingStock: {
      status: AsyncStatus;
      data: any;
    };
    givingDriver: {
      data: SimplifiedStock[] | null;
      driverId: string | null;
      status: AsyncStatus;
    };
    filters: Filters;
    forceRefresh: boolean;
  };
  transferStockStatus: AsyncStatus;
}

const initialState: State = {
  stockTransfer: {
    receivingDriver: {
      data: null,
      driverId: null,
      status: AsyncStatus.NotStarted,
    },
    receivingDriverPendingStock: {
      status: AsyncStatus.NotStarted,
      data: null,
    },
    givingDriver: {
      data: null,
      driverId: null,
      status: AsyncStatus.NotStarted,
    },
    filters: {
      receivingDriverId: null,
      givingDriverId: null,
      givingWarehouseId: null,
      receivingWarehouseId: null,
      receivingType: 'driver',
      givingType: 'driver',
    },
    forceRefresh: false,
  },
  transferStockStatus: AsyncStatus.NotStarted,
};

const slice = createSlice({
  name: 'stockList',
  initialState,
  reducers: {
    fetchReceivingDriverStockList(state) {
      if (
        state.stockTransfer.forceRefresh ||
        state.stockTransfer.receivingDriver.driverId !==
          state.stockTransfer.filters.receivingDriverId
      ) {
        state.stockTransfer.receivingDriver.status = AsyncStatus.Pending;
      }
    },
    fetchReceivingDriverStockListSuccess(
      state,
      { payload }: PA<ActionTypes.FetchReceivingDriverStockListSuccess>,
    ) {
      state.stockTransfer.receivingDriver.status = AsyncStatus.Success;
      state.stockTransfer.receivingDriver.data = payload.stock;
      state.stockTransfer.receivingDriver.driverId = payload.driverId;
    },
    fetchReceivingDriverStockListError(state) {
      state.stockTransfer.receivingDriver.status = AsyncStatus.Error;
      state.stockTransfer.forceRefresh = false;
    },
    fetchReceivingDriverStockListNoFilter(state) {
      state.stockTransfer.receivingDriver.status = AsyncStatus.NotStarted;
      state.stockTransfer.receivingDriver.data = null;
      state.stockTransfer.receivingDriver.driverId = null;
    },
    fetchGivingDriverStockList(state) {
      if (
        state.stockTransfer.forceRefresh ||
        state.stockTransfer.givingDriver.driverId !== state.stockTransfer.filters.givingDriverId
      ) {
        state.stockTransfer.givingDriver.status = AsyncStatus.Pending;
      }
    },
    fetchGivingDriverStockListSuccess(
      state,
      { payload }: PA<ActionTypes.FetchGivingDriverStockListSuccess>,
    ) {
      state.stockTransfer.givingDriver.status = AsyncStatus.Success;
      state.stockTransfer.givingDriver.data = payload.stock;
      state.stockTransfer.givingDriver.driverId = payload.driverId;
    },
    fetchGivingDriverStockListNoFilter(state) {
      state.stockTransfer.givingDriver.status = AsyncStatus.NotStarted;
      state.stockTransfer.givingDriver.data = null;
      state.stockTransfer.givingDriver.driverId = null;
    },
    fetchGivingDriverStockListError(state) {
      state.stockTransfer.givingDriver.status = AsyncStatus.Error;
      state.stockTransfer.forceRefresh = false;
    },
    setFilters(state, { payload }: PA<ActionTypes.SetFilters>) {
      if (payload.givingDriverId !== undefined) {
        state.stockTransfer.filters.givingDriverId = payload.givingDriverId;
      }
      if (payload.receivingDriverId !== undefined) {
        state.stockTransfer.filters.receivingDriverId = payload.receivingDriverId;
      }
      if (payload.givingWarehouseId !== undefined) {
        state.stockTransfer.filters.givingWarehouseId = payload.givingWarehouseId;
      }
      if (payload.receivingWarehouseId !== undefined) {
        state.stockTransfer.filters.receivingWarehouseId = payload.receivingWarehouseId;
      }
      if (payload.receivingType !== undefined) {
        state.stockTransfer.filters.receivingType = payload.receivingType;
      }
      if (payload.givingType !== undefined) {
        state.stockTransfer.filters.givingType = payload.givingType;
      }
    },
    fetchPendingStockList(state) {
      state.stockTransfer.receivingDriverPendingStock.status = AsyncStatus.Pending;
    },
    fetchPendingStockListSuccess(state, { payload }: PA<ActionTypes.FetchPendingStockListSuccess>) {
      state.stockTransfer.receivingDriverPendingStock.status = AsyncStatus.Success;
      state.stockTransfer.receivingDriverPendingStock.data = payload.stock;
    },
    fetchPendingStockListError(state) {
      state.stockTransfer.receivingDriverPendingStock.status = AsyncStatus.Error;
    },
    transferStock(state, _action: PA<ActionTypes.TransferStock>) {
      state.transferStockStatus = AsyncStatus.Pending;
    },
    transferStockFinish(state) {
      state.transferStockStatus = AsyncStatus.Success;
    },
    enableForceRefresh(state) {
      state.stockTransfer.forceRefresh = true;
    },
    disableForceRefresh(state) {
      state.stockTransfer.forceRefresh = false;
    },
  },
});

export declare namespace ActionTypes {
  export interface FetchReceivingDriverStockListSuccess {
    stock: SimplifiedStock[];
    driverId: string | null;
  }
  export interface FetchGivingDriverStockListSuccess {
    stock: SimplifiedStock[];
    driverId: string | null;
  }
  export interface FetchPendingStockListSuccess {
    stock: StockExchange[];
  }
  export interface SetFilters extends Partial<Filters> {}

  export interface TransferStock {
    data: {
      givingDriverId?: string | null;
      receivingDriverId?: string | null;
      givingWarehouseId?: string | null;
      receivingWarehouseId?: string | null;
      amount: number;
      productId: string;
      action?:
        | 'warehouseToWarehouse'
        | 'driverToDriver'
        | 'driverToWarehouse'
        | 'warehouseToDriver';
    };
  }
  export interface FetchGivingDriverStockListNoFilter {
    givingDriverId?: string | null;
    receivingDriverId?: string | null;
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectDriverStockListForceRefresh: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.stockTransfer.forceRefresh;
    }),
  makeSelectReceivingDriverStockList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.stockTransfer.receivingDriver.data;
    }),
  makeSelectReceivingDriverStockListLoadingStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.stockTransfer.receivingDriver.status;
    }),
  makeSelectGivingDriverStockList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.stockTransfer.givingDriver.data;
    }),
  makeSelectGivingDriverStockListLoadingStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.stockTransfer.givingDriver.status;
    }),
  makeSelectStockTransferFilters: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.stockTransfer.filters;
    }),
  makeSelectStockTransferDriverIds: () =>
    createSelector(makeSelectDomain(), (state) => {
      return {
        receivingDriverId: state.stockTransfer.receivingDriver.driverId,
        givingDriverId: state.stockTransfer.givingDriver.driverId,
      };
    }),
  makeSelectPendingStockList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.stockTransfer.receivingDriverPendingStock.data;
    }),
  makeSelectPendingStockListLoadingStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.stockTransfer.receivingDriverPendingStock.status;
    }),
  makeSelectTransferStockStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.transferStockStatus;
    }),
};
