import { call, put } from 'typed-redux-saga';
import { takeLatest, takeEvery } from 'redux-saga/effects';
import { reduce } from 'lodash';
import { RegionApiSaga } from 'api/RegionApi';
import { AuthorizedInitSteps } from 'services/authorization';
import * as Slice from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { PA } from '../../../utlis/State';
import { AuthApi } from '../../../api/AuthApi';
import { AppRoutes, navigate } from '../../../app/Navigation';
import { HttpError } from '../../../api/Request/RequestErrors';
import { addErrorToast } from '../../toast';
import { translate } from '../../../app/translations/useTranslation';

function* fetchRegionList() {
  const response = yield* RegionApiSaga.listRegions({ isActive: true });
  yield put(Slice.actions.fetchRegionListSuccess({ regions: response.list }));
}

function* fetchLocationsList({ payload }: PayloadAction<{ companyId: string }>) {
  const response = yield* RegionApiSaga.listActiveLocationsByCompanyId(payload.companyId);
  yield put(
    Slice.actions.fetchLocationsSuccess({
      companyId: payload.companyId,
      locations: response.list && response.list[0],
    }),
  );
}
function* fetchAllLocationsList() {
  const response = yield* RegionApiSaga.listActiveLocationsByCompanyId();
  yield put(
    Slice.actions.fetchAllLocationsSuccess({
      locations: reduce(
        response?.list ?? [],
        (result, value, key) => {
          // @ts-ignore
          result.push(...(value?.locations ?? []));
          return result;
        },
        [],
      ),
      /*locations:
        response.list &&
        reduce(
          response.list,
          (result, value, key) => {
            result = [...result, ...value.locations];
            return result;
          },
          [],
        ),*/
    }),
  );
}

function* updateLocation(action: PA<Slice.ActionTypes.UpdateLocation>) {
  try {
    yield* call(RegionApiSaga.updateLocation, {
      ...action.payload.updateData,
    });
    /*yield call(addSuccessToast, {
      message: translate('auth', 'notifications.productSelected'),
      id: 'registered',
    });*/
    // yield put(Slice.actions.addLocationsSuccess());
    // yield call(navigate, AppRoutes.root());
  } catch (e) {
    let errMsg;
    if (e instanceof HttpError && (e.status === 404 || e.status === 401)) {
      yield call(addErrorToast, {
        message: translate('settings', 'locations.notifications.updateFailed'),
        id: 'unknown_select_product_error',
      });
    } else {
      /*errMsg = e.response?.errorCode
        ? translate('register', `registration.fields.email.errors.${e.response.errorCode}`, {
            defaultValue: translate('register', 'registration.errors.unknown'),
          })
        : '';*/
    }
    // yield put(Slice.actions.addLocationsError({ error: errMsg }));
  }
}
function* addLocation(action: PA<Slice.ActionTypes.AddLocation>) {
  try {
    yield* call(RegionApiSaga.addLocation, {
      companyId: action.payload.companyId,
      location: action.payload.location,
    });
    /*yield call(addSuccessToast, {
      message: translate('auth', 'notifications.productSelected'),
      id: 'registered',
    });*/
    // yield put(Slice.actions.addLocationsSuccess());
    // yield call(navigate, AppRoutes.root());
  } catch (e) {
    let errMsg;
    if (e instanceof HttpError && (e.status === 404 || e.status === 401)) {
      yield call(addErrorToast, {
        message: translate('auth', 'notifications.unknownRegistrationError'),
        id: 'unknown_select_product_error',
      });
    } else {
      /*errMsg = e.response?.errorCode
        ? translate('register', `registration.fields.email.errors.${e.response.errorCode}`, {
            defaultValue: translate('register', 'registration.errors.unknown'),
          })
        : '';*/
    }

    // yield put(Slice.actions.addLocationsError({ error: errMsg }));
  }
  // Temp
  const response = yield* RegionApiSaga.listActiveLocationsByCompanyId(action.payload.companyId);
  yield put(
    Slice.actions.fetchLocationsSuccess({
      companyId: action.payload.companyId,
      locations: response.list && response.list[0],
    }),
  );
}

export function* saga() {
  yield takeLatest(Slice.actions.fetchRegionList, fetchRegionList);
  yield takeEvery(Slice.actions.fetchLocations, fetchLocationsList);
  yield takeEvery(Slice.actions.fetchAllLocations, fetchAllLocationsList);
  AuthorizedInitSteps.addStep(fetchRegionList);
  yield takeLatest(Slice.actions.addLocation, addLocation);
  yield takeLatest(Slice.actions.updateLocation, updateLocation);
}
