import { translate } from 'app/translations/useTranslation';
import { takeLeading } from 'redux-saga/effects';
import { addSuccessToast, addErrorToast } from 'services/toast';
import { call, put, select } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { MultiplierApiSaga } from 'api/MultiplierApi';
import * as Slice from './slice';
import { add, sub, startOfMonth, endOfMonth } from 'date-fns';
import { ApiMultiplierMapper } from './ApiMultiplierMapper';

function* createMultiplier({ payload }: PA<Slice.ActionTypes.CreateMultiplier>) {
  try {
    const response = yield* call(MultiplierApiSaga.createMultiplier, {
      dateFrom: payload.dateFrom,
      dateTo: payload.dateTo,
      multiplier: payload.multiplier,
      description: payload.description,
      regions: payload.regions,
      companyIds: payload.companyIds,
      timeRanges: payload.timeRanges,
    });

    yield call(addSuccessToast, {
      message: translate('multiplierCalendar', 'notifications.multiplierCreated'),
    });
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.createMultiplierFinish());
}

function* fetchMultiplierList({ payload }: PA<Slice.ActionTypes.FetchMultiplierList>) {
  try {
    const response = yield* MultiplierApiSaga.listMultipliers({
      dateFrom: startOfMonth(sub(payload.date, { months: 5 })),
      dateTo: endOfMonth(add(payload.date, { months: 5 })),
    });
    yield put(
      Slice.actions.fetchMultiplierListSuccess({
        multipliers: response.multipliers.map((multiplier) =>
          ApiMultiplierMapper.mapToMultiplier(multiplier),
        ),
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.fetchMultiplierListError());
  }
}

function* deactivateMultiplier({ payload }: PA<Slice.ActionTypes.DeactivateMultiplier>) {
  try {
    yield* call(MultiplierApiSaga.deactivateMultiplier, {
      multiplierId: payload.multiplierId,
      deactivationReason: payload.deactivationReason,
    });

    yield call(addSuccessToast, {
      message: translate('multiplierCalendar', 'notifications.multiplierDeactivated'),
    });
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.deactivateMultiplierFinish());
}

export function* saga() {
  yield takeLeading(Slice.actions.createMultiplier, createMultiplier);
  yield takeLeading(Slice.actions.fetchMultiplierList, fetchMultiplierList);
  yield takeLeading(Slice.actions.deactivateMultiplier, deactivateMultiplier);
}
