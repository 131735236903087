import React, { useEffect, useMemo, useState } from 'react';
import { map, mapValues, omitBy, isNil } from 'lodash';
import { Controller, FormProvider, SubmitHandler, useFormContext } from 'react-hook-form';
import * as Styled from './styled';
import LoadingButton from '@mui/lab/LoadingButton';

import { AsyncStatus } from 'utlis/State';
import { useTranslation } from 'app/translations/useTranslation';
import {
  AddLocationFormValues,
  useAddLocationForm,
  useAddLocationFormReset,
} from '../hooks/useAddLocationForm';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@mui/material/Alert';
import { Location, LocationWorkingSettings } from '../../../../../model/Location';
import { MuiHandlers } from '../../../../../externals/MaterialUI/Handlers';
import MenuItem from '@mui/material/MenuItem';
import { LabeledSelect } from '../../../../../externals/MaterialUI/LabeledSelect';
import { Divider } from '@mui/material';
import { DayWeekSettings, WeekDay, WeeklyCalendar } from '../WeeklyCalendar/WeeklyCalendar';
import { ApiLocationMapper } from '../../../state/LocationMapper';

interface Props {
  onSubmit?: (location: Partial<Location>) => void;
  onSubmitFinish?: VoidFunction;
  location?: Partial<Location>;
}

const mapFormToApiWorkingSettings = (workingSettings: {
  [day in WeekDay]: DayWeekSettings;
}): LocationWorkingSettings | undefined => {
  if (map(workingSettings, 'isOpen').includes(true)) {
    return {
      workingDays: mapValues(workingSettings, (settings, dayWeek) => {
        return settings.isOpen === true;
      }),
      nonStandardWorkingHours: omitBy(
        mapValues(workingSettings, (settings, dayWeek) => {
          if (
            !!settings?.from?.fullDate &&
            !!settings?.to?.fullDate &&
            settings.from.fullDate < settings.to.fullDate
          ) {
            return {
              from: {
                hour: settings?.from?.hour,
                minute: settings?.from?.minute,
              },
              to: {
                hour: settings?.to?.hour,
                minute: settings?.to?.minute,
              },
            };
          }
          return undefined;
        }),
        isNil,
      ),
    };
  }
  return undefined;
};

export function UpdateLocationForm({ onSubmit, onSubmitFinish, location }: Props) {
  const { t } = useTranslation('settings');
  // const { cancelOrder, cancelOrderStatus } = useCancelOrder();

  const form = useAddLocationForm();
  const reset = useAddLocationFormReset();

  const [daysTimeRange, setDaysTimeRange] = useState<{
    [day in WeekDay]: DayWeekSettings;
  }>({
    [WeekDay.monday]: {},
    [WeekDay.tuesday]: {},
    [WeekDay.wednesday]: {},
    [WeekDay.thursday]: {},
    [WeekDay.friday]: {},
    [WeekDay.saturday]: {},
    [WeekDay.sunday]: {},
  });

  const submit: SubmitHandler<AddLocationFormValues> = (formValues) => {
    if (onSubmit) {
      const workingSettings = ApiLocationMapper.mapWorkingSettingsToApi(daysTimeRange);
      onSubmit({
        locationId: location?.id,
        name: formValues.name,
        address: {
          city: formValues.city,
          street: formValues.street,
          zipCode: formValues.zipCode,
        },
        phone: formValues.phone,
        active: formValues.active === 'enabled',
        defaultNote: formValues.defaultNote,
        ...(workingSettings ? { workingSettings } : {}),
      });
    } else {
      // handle default API add location call
    }
    if (onSubmitFinish) {
      onSubmitFinish();
    }
  };

  const submitError = useMemo(() => {
    return undefined;
  }, []);

  const onReset = () => {
    reset();
  };

  useEffect(() => {
    if (location) {
      form.setValue('name', location?.name ?? '');
      form.setValue('street', location?.address?.street ?? '');
      form.setValue('city', location?.address?.city ?? '');
      form.setValue('zipCode', location?.address?.zipCode ?? '');
      form.setValue('phone', location?.phone ?? '');
      form.setValue('active', location?.active ? 'enabled' : 'disabled');
      form.setValue('defaultNote', location?.defaultNote ?? '');

      const workingSettings = ApiLocationMapper.mapWorkingSettingsToLocal(location.workingSettings);
      if (workingSettings) {
        setDaysTimeRange(workingSettings);
      }
    }
  }, [form, location]);
  return (
    <FormProvider {...form}>
      <Styled.Form onSubmit={form.handleSubmit<AddLocationFormValues>(submit)} onReset={onReset}>
        <Styled.FieldsContainer container spacing={2} justifyContent={'center'}>
          {submitError && (
            <Styled.FieldContainer item xs={12}>
              <FormHelperText error>{submitError}</FormHelperText>
            </Styled.FieldContainer>
          )}
          <Styled.FieldContainer item xs={12} sm={12} md={6} lg={6}>
            <Styled.Input
              autoComplete="off"
              name={'name'}
              label={t('locations.form.fields.name.label')}
              placeholder={t('locations.form.fields.name.placeholder')}
              dataCy={'input-name'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={6} lg={6}>
            <Styled.Input
              autoComplete="off"
              name={'phone'}
              label={t('locations.form.fields.phone.label')}
              placeholder={t('locations.form.fields.phone.placeholder')}
              dataCy={'input-phone'}
            />
            <Alert severity={'info'}>{t('locations.form.fields.phone.helperText')}</Alert>
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={4} lg={4}>
            <Styled.Input
              autoComplete="off"
              name={'street'}
              label={t('locations.form.fields.street.label')}
              placeholder={t('locations.form.fields.street.placeholder')}
              dataCy={'input-street'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={4} lg={4}>
            <Styled.Input
              autoComplete="off"
              name={'zipCode'}
              label={t('locations.form.fields.zipCode.label')}
              placeholder={t('locations.form.fields.zipCode.placeholder')}
              dataCy={'input-zipCode'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={4} lg={4}>
            <Styled.Input
              autoComplete="off"
              name={'city'}
              label={t('locations.form.fields.city.label')}
              placeholder={t('locations.form.fields.city.placeholder')}
              dataCy={'input-city'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={4} lg={4}>
            <Styled.Input
              autoComplete="off"
              name={'defaultNote'}
              label={t('locations.form.fields.defaultNote.label')}
              placeholder={t('locations.form.fields.defaultNote.placeholder')}
              dataCy={'input-defaultNote'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={4} lg={4}>
            <Controller
              name="active"
              control={form.control}
              render={({ field }) => {
                return (
                  <LabeledSelect
                    name={'active'}
                    id={'select-active'}
                    label={t('locations.form.fields.active.label')}
                    isMultiSelector={false}
                    size="small"
                    onChange={field.onChange}
                    value={field.value}
                    key={field.value}
                  >
                    <MenuItem value={'enabled'}>
                      {t('locations.form.fields.active.options.enabled')}
                    </MenuItem>
                    <MenuItem value={'disabled'}>
                      {t('locations.form.fields.active.options.disabled')}
                    </MenuItem>
                  </LabeledSelect>
                );
              }}
            />
          </Styled.FieldContainer>
        </Styled.FieldsContainer>

        <Divider />

        <Styled.FieldsContainer container spacing={2} justifyContent={'center'}>
          <WeeklyCalendar daysTimeRange={daysTimeRange} onChange={setDaysTimeRange} />
        </Styled.FieldsContainer>

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          /*disabled={cancelOrderStatus === AsyncStatus.Pending}
          loading={cancelOrderStatus === AsyncStatus.Pending}*/
        >
          {t('locations.form.submitButton')}
        </LoadingButton>
      </Styled.Form>
    </FormProvider>
  );
}
