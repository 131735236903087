import React, { useCallback, useMemo, useState } from 'react';
import { map } from 'lodash';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Styled from './styled';
import LoadingButton from '@mui/lab/LoadingButton';

import { AsyncStatus } from 'utlis/State';
import { useTranslation } from 'app/translations/useTranslation';

import { Input } from 'components/ReactHookForm/Input/Input';
import { Select } from 'components/ReactHookForm/Select/Select';
import { Checkbox } from 'components/ReactHookForm/Checkbox/Checkbox';

import {
  AddLocationFormValues,
  useAddLocationForm,
  useAddLocationFormReset,
} from '../hooks/useAddLocationForm';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@mui/material/Alert';
import { Location } from '../../../../../model/Location';
import { getHours, getMinutes, isDate } from 'date-fns';
import { WeeklyCalendarDayRow } from './WeeklyCalendarDayRow';

interface Props {
  onChange: (settings: {
    [day in WeekDay]: DayWeekSettings;
  }) => void;
  daysTimeRange: {
    [day in WeekDay]: DayWeekSettings;
  };
}

export enum WeekDay {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export interface DayWeekSettings {
  isOpen?: boolean;
  from?: {
    fullDate?: Date;
    minute?: number;
    hour?: number;
  };
  to?: {
    fullDate?: Date;
    minute?: number;
    hour?: number;
  };
}

export function WeeklyCalendar({ onChange, daysTimeRange }: Props) {
  const { t } = useTranslation('settings');
  // const { cancelOrder, cancelOrderStatus } = useCancelOrder();

  const setDayTimeRange = (day: WeekDay, settings: DayWeekSettings) => {
    onChange({
      ...(daysTimeRange ?? {}),
      [day]: settings,
    });
  };
  return (
    <Styled.FieldsContainer container spacing={2} justifyContent={'center'}>
      {map(daysTimeRange, (settings: DayWeekSettings, key: WeekDay) => {
        return (
          <Styled.FieldContainer item xs={12}>
            <WeeklyCalendarDayRow weekDay={key} settings={settings} onChange={setDayTimeRange} />
          </Styled.FieldContainer>
        );
      })}
    </Styled.FieldsContainer>
  );
}
