// Core libraries
import React, { useMemo, useState } from 'react';

// External libs and components
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Internal libs

// Internal components
import { ZipCode, ZipCodeFormData } from '../model/ZipCode';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

// Props type
type ZipCodeControlsProps = {
  showMarkers: boolean;
  setShowMarkers: (checked: boolean) => void;
  showAreas: boolean;
  setShowAreas: (checked: boolean) => void;
  formData: ZipCodeFormData;
  setFormData: (data: ZipCodeFormData) => void;
  submitFormData: () => void;
  loading: boolean;
};
// Component
const ZipCodeControls = ({
  showMarkers,
  setShowMarkers,
  showAreas,
  setShowAreas,
  formData,
  setFormData,
  submitFormData,
  loading,
}: ZipCodeControlsProps) => {
  const handleChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const classes = useStyles();
  return (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Dostępne warstwy</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showMarkers}
                onChange={(event) => setShowMarkers(event.target.checked)}
                name="showMarkers"
              />
            }
            label="Centroidy kodów"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showAreas}
                onChange={(event) => setShowAreas(event.target.checked)}
                name="showAreas"
              />
            }
            label="Obszary kodów"
          />
        </FormGroup>
      </FormControl>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Wyszukaj kody</FormLabel>
        <FormGroup>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              required
              className={classes.formControl}
              value={formData.street}
              onChange={handleChange}
              name="street"
              type="text"
              label="Ulica"
              focused
            />

            <TextField
              required
              className={classes.formControl}
              value={formData.zipCode}
              onChange={handleChange}
              name="zipCode"
              type="text"
              label="Kod pocztowy"
              focused
            />
            <TextField
              required
              className={classes.formControl}
              value={formData.city}
              onChange={handleChange}
              name="city"
              type="text"
              label="Miasto"
              focused
            />
            <TextField
              required
              type="number"
              className={classes.formControl}
              value={formData.radius}
              onChange={handleChange}
              name="radius"
              label="Promień"
              defaultValue="7"
            />
            <Button variant="contained" color="primary" onClick={submitFormData} disabled={loading}>
              Wyszukaj
            </Button>
          </form>
        </FormGroup>
      </FormControl>
    </>
  );
};

export default ZipCodeControls;
