import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';
import { AccountType } from '../../../api/OperatorApi';

export type UserListFilters = {
  companyId: string | null;
  accountType: string | null;
  activeStatus: string;
};

export function useUserList(filters: UserListFilters) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Slice.actions.fetchUserList());
  }, [dispatch]);

  const userList = useSelector(Slice.selectors.makeSelectUserList());

  const filteredUsers = userList.data.filter((user) => {
    if (user.assignedCompanies.toAll === true) {
      // return true;
    }

    if (filters.companyId !== null && !user.assignedCompanies.ids.includes(filters.companyId)) {
      return false;
    }

    if (filters.accountType !== null && user.accountType !== filters.accountType) {
      return false;
    }

    if (filters.activeStatus === 'active' && user.accountType === AccountType.Deactivated) {
      return false;
    }

    if (filters.activeStatus === 'inactive' && user.accountType !== AccountType.Deactivated) {
      return false;
    }

    return true;
  });

  return {
    isLoading: userList.status === AsyncStatus.Pending,
    hasError: userList.status === AsyncStatus.Error,
    data: filteredUsers,
  };
}
