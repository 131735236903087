import { DecodedToken, UserType } from 'api/AuthApi';
import jwt_decode from 'jwt-decode';
import { every } from 'lodash';
import { OperatorScope } from 'api/OperatorApi';
import { Permissions } from '../Permissions';

function userType(userType: UserType) {
  return (decodedToken: DecodedToken) => {
    return decodedToken.type === userType;
  };
}

function scope(accessScope: OperatorScope | OperatorScope[], explicit: boolean = false) {
  return (decodedToken: DecodedToken) => {
    if (!explicit && decodedToken.scope.includes(OperatorScope.OmniValue)) {
      return true;
    }
    for (const scope of Array.isArray(accessScope) ? accessScope : [accessScope]) {
      if (decodedToken.scope.includes(scope)) {
        return true;
      }
    }
    return false;
  };
}

type CheckFn = (decodedToken: DecodedToken) => boolean;
function check(...fns: CheckFn[]) {
  return (decodedToken: DecodedToken) => {
    return every(fns.map((checkingFn) => checkingFn(decodedToken)));
  };
}

class Service {
  public map(token: string): Permissions {
    const decodedToken = jwt_decode<DecodedToken>(token);
    return {
      order: {
        canAccessPage: check(
          userType(UserType.Operator),
          scope([OperatorScope.OrdersRead, OperatorScope.Orders]),
        )(decodedToken),
        canWrite: check(
          userType(UserType.Operator),
          scope([OperatorScope.OrdersWrite, OperatorScope.Orders]),
        )(decodedToken),
        canView: {
          cancellation: !!decodedToken.canView?.cancellation,
          pinCode: !!decodedToken.canView?.pinCode,
          sensitiveData: !!decodedToken.canView?.sensitiveData,
          holdOrder: !!decodedToken.canView?.holdOrder,
          orderEstimations: !!decodedToken.canView?.orderEstimations,
          orderRoute: !!decodedToken.canView?.orderRoute,
          comments: !!decodedToken.canView?.comments,
          changeDriver: !!decodedToken.canView?.changeDriver,
          driverInfo: !!decodedToken.canView?.driverInfo,
          driverPayout: !!decodedToken.canView?.driverPayout,
        },
      },
      company: {
        canAccessPage: check(
          userType(UserType.Operator),
          scope([OperatorScope.SuperAdmin, OperatorScope.Companies]),
        )(decodedToken),
        canWrite: check(
          userType(UserType.Operator),
          scope([OperatorScope.SuperAdmin, OperatorScope.Companies]),
        )(decodedToken),
      },
      report: {
        canAccessPage: check(
          userType(UserType.Operator),
          scope([
            OperatorScope.Reports,
            OperatorScope.ReportDriverActivity,
            OperatorScope.ReportFull,
            OperatorScope.ReportOffers,
            OperatorScope.ReportCorrections,
            OperatorScope.ReportDriverActivity,
            OperatorScope.ReportDriversFeedback,
            OperatorScope.ReportDriversStock,
          ]),
        )(decodedToken),
        reports: {
          canAccessDriverActivityReport: check(
            userType(UserType.Operator),
            scope([OperatorScope.Reports, OperatorScope.ReportDriverActivity]),
          )(decodedToken),
          canAccessFullReport: check(
            userType(UserType.Operator),
            scope([OperatorScope.Reports, OperatorScope.ReportFull]),
          )(decodedToken),
          canAccessOffersReport: check(
            userType(UserType.Operator),
            scope([OperatorScope.Reports, OperatorScope.ReportOffers]),
          )(decodedToken),
          canAccessDriversStockReport: check(
            userType(UserType.Operator),
            scope([OperatorScope.Reports, OperatorScope.ReportDriversStock]),
          )(decodedToken),
          canAccessCorrectionsReport: check(
            userType(UserType.Operator),
            scope([OperatorScope.Reports, OperatorScope.ReportCorrections]),
          )(decodedToken),
          canAccessDriversFeedbackReport: check(
            userType(UserType.Operator),
            scope([OperatorScope.Reports, OperatorScope.ReportDriversFeedback]),
          )(decodedToken),
          canAccessAuditStockMovementReport: check(
            userType(UserType.Operator),
            scope([OperatorScope.Reports, OperatorScope.ReportAuditStockMovement]),
          )(decodedToken),
          canAccessStockExchangeReport: check(
            userType(UserType.Operator),
            scope([OperatorScope.Reports, OperatorScope.ReportStockExchange]),
          )(decodedToken),
        },
      },
      user: {
        canAccessPage: check(
          userType(UserType.Operator),
          scope(OperatorScope.Operators),
        )(decodedToken),
      },
      driver: {
        canAccessPage: check(
          userType(UserType.Operator),
          scope(OperatorScope.Drivers),
        )(decodedToken),
        notifications: {
          canAccessSendPushNotification: check(
            userType(UserType.Operator),
            scope([OperatorScope.Notifications, OperatorScope.SuperAdmin]),
          )(decodedToken),
        },
      },
      warehouse: {
        canAccessPage: check(
          userType(UserType.Operator),
          scope(OperatorScope.Warehouse),
        )(decodedToken),
      },
      stock: {
        canAccessPage: check(userType(UserType.Operator), scope(OperatorScope.Stock))(decodedToken),
      },
      multiplier: {
        canAccessPage: check(
          userType(UserType.Operator),
          scope(OperatorScope.SuperAdmin),
        )(decodedToken),
      },
      correction: {
        canAccessPage: check(
          userType(UserType.Operator),
          scope(OperatorScope.Corrections),
        )(decodedToken),
      },
      wallet: {
        canAccessPage: check(
          userType(UserType.Operator),
          scope(OperatorScope.Wallet, true),
        )(decodedToken),
      },
      zipCode: {
        canAccessPage: check(
          userType(UserType.Operator),
          scope(OperatorScope.ZipCodes, true),
        )(decodedToken),
      },
    };
  }
}

export const ApiTokenToPermissionsMapper = new Service();
