const rootUrl = '/orders';

const LinkFactory = {
  root: () => rootUrl,
  orderList: () => `${rootUrl}/list`,
  orderCreate: () => `${rootUrl}/new`,
  verifyPickup: () => `/pin`,
};

export default LinkFactory;
