import React, { useCallback, useState } from 'react';

// import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Company } from 'model/Company';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Data from 'components/Data/Data';
import { LocationsManager } from '../../../SettingsPage/components/Location/LocationsManager';
import { Location } from '../../../../model/Location';
import { useAuth } from '../../../../services/authorization';
import { useLocations } from '../../../../services/regions/state/hooks/useLocations';

// Props type
type Props = { company: Company };

// Component
const Locations = ({ company }: Props) => {
  const { t } = useTranslation('common');

  const { locations, addLocation, updateLocation } = useLocations(company.id);

  const submitLocation = useCallback(
    (newLocation) => {
      addLocation(company.id, newLocation);
    },
    [company],
  );

  return (
    <Card>
      <CardHeader title={t('config.locations.label')} />
      <CardContent>
        <LocationsManager
          locations={locations}
          onAddLocation={submitLocation}
          onUpdateLocation={updateLocation}
          max={undefined}
          admin={true}
        />
        {/*<pre>{JSON.stringify(company.deliverySettings?.offersLimit, undefined, 2)}</pre>*/}
      </CardContent>
    </Card>
  );
};

export default Locations;
