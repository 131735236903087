import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MUIBox from '@mui/material/Box';

import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';

// Props type
type Props = {
  label: string;
  listItems: { value: string; display: string }[];
  onChange?: (item?: { value: string; display: string }) => void;
  filterable?: boolean;
};

// Component
const FilterableLabeledSelect = ({ listItems, onChange, label, filterable = true }: Props) => {
  const { t } = useTranslation('common');

  const [selected, setSelected] = useState<string>('notSelectedValue');
  const [emptyForm, setEmptyForm] = useState<boolean>(false);

  const [filter, setFilter] = useState<string>('');
  const filteredItems = useMemo(() => {
    const filterParts = filter
      .split(' ')
      .filter((p) => !!p)
      .map((p) => p.toLowerCase());
    return listItems?.filter((item) => {
      return filterParts.every((part) => item.display.toLowerCase().includes(part));
    });
  }, [filter, listItems, selected]);

  // remove selection if selected item not in filtered items
  useEffect(() => {
    if (selected && !filteredItems?.find((item) => item.value === selected)) {
      setSelected('notSelectedValue');
    }
  }, [filteredItems, selected, setSelected]);

  const selectedItem = useMemo(
    () => listItems?.find((item) => item.value === selected),
    [selected],
  );
  const handleChange = (itemValue: string) => {
    setSelected(itemValue || 'notSelectedValue');
  };
  useEffect(() => {
    if (selected === 'notSelectedValue') {
      setEmptyForm(true);
    }
  }, [selected]);
  useEffect(() => {
    if (onChange) {
      console.log('fired', selectedItem);
      onChange(selectedItem);
    }
  }, [selectedItem]);

  return (
    <Grid container spacing={2}>
      {filterable && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label={t('filterSelect')}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Box>
          <LabeledSelect
            onChange={(e) => handleChange(e.target.value as string)}
            id="location-selector"
            value={selected || 'notSelectedValue'}
            size="small"
            fullWidth
            label={label}
          >
            <MenuItem value={'notSelectedValue'}>-</MenuItem>
            {filteredItems?.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                <Typography variant="inherit" noWrap>
                  {item.display}
                </Typography>
              </MenuItem>
            ))}
          </LabeledSelect>
          {selected !== 'notSelectedValue' && (
            <IconButton>
              <CloseIcon onClick={() => setSelected('notSelectedValue')} />
            </IconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const Box = styled(MUIBox)({
  display: 'flex',
});

export default FilterableLabeledSelect;
