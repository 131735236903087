// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { Popup, Circle, FeatureGroup } from 'react-leaflet';

// Internal libs

// Props type
type ZipCodesRadiusProps = {
  lat: number;
  lng: number;
  radius: number;
};

// Component
const ZipCodesRadius = ({ lat, lng, radius }: ZipCodesRadiusProps) => {
  return (
    <>
      <FeatureGroup>
        <Popup>
          {lat}
          <br />
          {lng}
          <br />
          Promień: {radius}km
        </Popup>
        <Circle center={[lat, lng]} radius={radius * 1000} />
      </FeatureGroup>
    </>
  );
};

export default ZipCodesRadius;
