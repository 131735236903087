// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import L, { Point, DivIcon, GeoJSON } from 'leaflet';
import { Popup, Circle, Polygon, FeatureGroup, Marker, Tooltip } from 'react-leaflet';
import MarkerIcon from 'leaflet/dist/images/marker-icon.png';
import MarkerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import MarkerShadow from 'leaflet/dist/images/marker-shadow.png';

// Internal libs

// Internal components
import { ZipCode } from '../model/ZipCode';
//@ts-ignore
delete L.Icon.Default.prototype?._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: MarkerIcon2x,
  iconUrl: MarkerIcon,
  shadowUrl: MarkerShadow,
});
const myCustomColour = '#ff0e0e';

const markerHtmlStyles = `
  background-color: ${myCustomColour};
  width: 1rem;
  height: 1rem;
  display: block;
  position: relative;
  border-radius: 1rem 1rem 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF`;

const icon = L.divIcon({
  className: 'my-custom-pin',
  iconAnchor: [0, 24],
  /*labelAnchor: [-6, 0],*/
  popupAnchor: [0, -36],
  html: `<span style="${markerHtmlStyles}" />`,
});

// Props type
type ZipCodeMarkersProps = {
  zipCodes: ZipCode[];
  alternate?: boolean;
  showAreas: boolean;
  showMarkers: boolean;
};
const fillBlueOptions = { fillColor: '#004488', color: '#62adff' };
const fillRedOptions = { fillColor: '#ae0000', color: '#ff7676' };
// Component
const ZipCodeMarkers = ({ zipCodes, alternate, showMarkers, showAreas }: ZipCodeMarkersProps) => {
  return (
    <>
      {zipCodes.map((zipCode: ZipCode, idx) => {
        return (
          <FeatureGroup key={idx}>
            {showMarkers && (
              <>
                <Tooltip>{zipCode.name}</Tooltip>
                <Marker
                  {...(alternate ? { icon: icon } : {})}
                  position={[zipCode.centroid.coordinates[1], zipCode.centroid.coordinates[0]]}
                />
              </>
            )}
            {showAreas &&
              zipCode.geometry &&
              zipCode.geometry.coordinates.map((coords: [number, number][][], idz: number) => {
                const c = GeoJSON.coordsToLatLngs(coords, 1);
                return (
                  <FeatureGroup key={idz}>
                    <Tooltip>{zipCode.name}</Tooltip>
                    <Polygon
                      positions={c}
                      pathOptions={alternate ? fillRedOptions : fillBlueOptions}
                    />
                  </FeatureGroup>
                );
              })}
          </FeatureGroup>
        );
      })}
    </>
  );
};

export default ZipCodeMarkers;
