// Core libraries
import React, { useState, useEffect } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import { LatLng } from 'leaflet';

// Internal libs
import { ZipCode } from '../model/ZipCode';

// Internal components
import ZipCodeMarkers from './ZipCodeMarkers';
import ZipCodesRadius from './ZipCodesRadius';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    mapContainer: {
      height: '100vh',
      width: '65vw',
    },
  }),
);

// Props type
type ZipCodesMapProps = {
  zipCodes: ZipCode[];
  extraZipCodes: ZipCode[];
  lat?: number;
  lng?: number;
  radius: number;
  initZoom?: number;
  initCenter?: LatLng | [number, number];
  showMarkers: boolean;
  showAreas: boolean;
};

// Component
const ZipCodesMap = ({
  zipCodes,
  extraZipCodes,
  lat,
  lng,
  radius,
  initZoom = 7,
  initCenter = [53.124578, 18.018055],
  showAreas,
  showMarkers,
}: ZipCodesMapProps) => {
  const styles = useStyles();

  const [center, setCenter] = useState(initCenter);
  const [zoom] = useState(initZoom);
  function MapViewChanger({ lat, lng }: { lat?: number; lng?: number }) {
    const map = useMap();
    useEffect(() => {
      if (lat && lng) {
        map.setView([lat, lng], 10);
      }
    }, [lat, lng]);
    return null;
  }

  return (
    <MapContainer center={center} zoom={zoom} className={styles.mapContainer}>
      <MapViewChanger lat={lat} lng={lng} />
      {/* @ts-ignore */}
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {lat && lng && <ZipCodesRadius lat={lat} lng={lng} radius={radius} />}

      <ZipCodeMarkers showMarkers={showMarkers} showAreas={showAreas} zipCodes={zipCodes} />
      <ZipCodeMarkers
        showMarkers={showMarkers}
        showAreas={showAreas}
        zipCodes={extraZipCodes}
        alternate={true}
      />
    </MapContainer>
  );
};

export default ZipCodesMap;
