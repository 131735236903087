import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';
import { ZipCode, ZipCodeFormData } from '../model/ZipCode';

export interface ZipCodeListing {
  list: ZipCode[];
  listOutOfBounds: ZipCode[];
  hasError: boolean;
  isLoading: boolean;
}

export function useZipCodes() {
  const dispatch = useDispatch();

  const zipCodes = useSelector(Slice.selectors.makeSelectZipCodesList());
  const zipCodesOutOfBounds = useSelector(Slice.selectors.makeSelectZipCodesOutOfBoundsList());
  const centerPoint = useSelector(Slice.selectors.makeSelectCenterPoint());
  const loadingStatus = useSelector(Slice.selectors.makeSelectZipCodesListStatus());
  const zipCodeListing: ZipCodeListing = {
    list: zipCodes,
    listOutOfBounds: zipCodesOutOfBounds,
    hasError: loadingStatus === AsyncStatus.Error,
    isLoading: [AsyncStatus.Pending].includes(loadingStatus),
  };

  return {
    zipCodeListing,
    centerPoint,
    getZipCodes: (data: ZipCodeFormData) => {
      dispatch(
        Slice.actions.getZipCodes({
          street: data.street || '',
          city: data.city || '',
          zipCode: data.zipCode || '',
          radius: data.radius,
        }),
      );
    },
  };
}
