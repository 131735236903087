import { styled } from '@mui/material/styles';
import { Button } from 'components/Button/Buttons';

export const Box = styled('div')(
  ({ theme }) => `
  margin: ${theme.spacing(1)} ${theme.spacing(5)};
`,
);

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

export const DetailsBox = styled('div')(
  ({ theme }) => `
 
  margin-bottom: ${theme.spacing(3)};
`,
);

export const SubmitButton = styled(Button)`
  width: 200px;
  align-self: end;
`;
