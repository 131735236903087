import { createSelector, createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { AsyncStatus, PA } from 'utlis/State';
import {
  User,
  CreateUserParams,
  UpdateUserParams,
  ChangeOperatorPasswordParams,
  DeactivateUserParams,
  ActivateUserParams,
} from '../User';

export interface State {
  userList: {
    data: User[];
    status: AsyncStatus;
  };
  createUserStatus: AsyncStatus;
  updateUserStatus: AsyncStatus;
  changeOperatorPasswordStatus: AsyncStatus;
  deactivateUserStatus: AsyncStatus;
  activateUserStatus: AsyncStatus;
}

const initialState: State = {
  userList: {
    data: [],
    status: AsyncStatus.NotStarted,
  },
  createUserStatus: AsyncStatus.NotStarted,
  updateUserStatus: AsyncStatus.NotStarted,
  changeOperatorPasswordStatus: AsyncStatus.NotStarted,
  deactivateUserStatus: AsyncStatus.NotStarted,
  activateUserStatus: AsyncStatus.NotStarted,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserList(state) {
      state.userList.status = AsyncStatus.Pending;
    },
    fetchUserListSuccess(state, { payload }: PA<ActionTypes.FetchUserListSuccess>) {
      state.userList.status = AsyncStatus.Success;
      state.userList.data = orderBy(payload.users, ['createdAt'], ['desc']);
    },
    fetchUserListError(state) {
      state.userList.status = AsyncStatus.Error;
    },
    createUser(state, _action: PA<ActionTypes.CreateUser>) {
      state.createUserStatus = AsyncStatus.Pending;
    },
    createUserFinish(state) {
      state.createUserStatus = AsyncStatus.Success;
    },
    updateUser(state, _action: PA<ActionTypes.UpdateUser>) {
      state.updateUserStatus = AsyncStatus.Pending;
    },
    updateUserFinish(state) {
      state.updateUserStatus = AsyncStatus.Success;
    },
    changeOperatorPassword(state, _action: PA<ActionTypes.ChangeOperatorPassword>) {
      state.changeOperatorPasswordStatus = AsyncStatus.Pending;
    },
    changeOperatorPasswordFinish(state) {
      state.changeOperatorPasswordStatus = AsyncStatus.Success;
    },
    deactivateUser(state, _action: PA<ActionTypes.DeactivateUser>) {
      state.deactivateUserStatus = AsyncStatus.Pending;
    },
    deactivateUserFinish(state) {
      state.deactivateUserStatus = AsyncStatus.Success;
    },
    activateUser(state, _action: PA<ActionTypes.ActivateUser>) {
      state.activateUserStatus = AsyncStatus.Pending;
    },
    activateUserFinish(state) {
      state.activateUserStatus = AsyncStatus.Success;
    },
  },
});

export declare namespace ActionTypes {
  export interface FetchUserListSuccess {
    users: User[];
  }

  export interface CreateUser {
    data: CreateUserParams;
  }

  export interface UpdateUser {
    data: UpdateUserParams;
  }

  export interface ChangeOperatorPassword extends ChangeOperatorPasswordParams {}
  export interface DeactivateUser extends DeactivateUserParams {}
  export interface ActivateUser extends ActivateUserParams {}
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectChangeOperatorPasswordStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.changeOperatorPasswordStatus;
    }),
  makeSelectDeactivateUserStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.deactivateUserStatus;
    }),
  makeSelectUserList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.userList;
    }),
  makeSelectCreateUserStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.createUserStatus;
    }),
  makeSelectUpdateUserStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.updateUserStatus;
    }),
  makeSelectActivateUserStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.activateUserStatus;
    }),
};
