import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../slice';
import * as selectors from '../selectors';
import { Location } from '../../../../model/Location';

export const useLocations = (companyId: string) => {
  const dispatch = useDispatch();

  const fetchLocations = useCallback(() => {
    if (companyId) {
      dispatch(actions.fetchLocations({ companyId }));
    }
  }, [dispatch, companyId]);

  const fetchNewLocations = useCallback(
    (company) => {
      dispatch(actions.fetchLocations({ companyId: company }));
    },
    [dispatch],
  );

  const fetchAllLocations = useCallback(() => {
    dispatch(actions.fetchAllLocations({}));
  }, [dispatch]);

  const locations = useSelector(selectors.makeSelectCompanyLocations(companyId));

  useEffect(() => {
    fetchLocations();
  }, [companyId, fetchLocations]);

  const addLocation = useCallback(
    (companyId: string, payload: Partial<Location>) => {
      dispatch(actions.addLocation({ companyId, location: payload }));
    },
    [dispatch],
  );

  const updateLocation = useCallback(
    (locationId: string, payload: Partial<Location>) => {
      dispatch(actions.updateLocation({ locationId, updateData: payload }));
    },
    [dispatch],
  );

  return {
    locations,
    fetchLocations,
    addLocation,
    fetchNewLocations,
    updateLocation,
    fetchAllLocations,
    locationsAll: useSelector(selectors.makeSelectCompanyLocationsAll()),
  };
};
