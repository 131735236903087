import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';

export type FindAndVerifyPickUpFormValues = {
  pin: string;
  createdAt: Date;
  orderNo: string;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object().shape({
      pin: yup.string().required(t('required')),
      createdAt: yup.date().required(t('required')),
      orderNo: yup.string().min(4).max(4).required(t('required')),
    });
  }, [t]);
}

export const useFindAndVerifyPickUpFormReset = () => {
  const form = useFormContext();
  return useCallback(() => {
    form.reset();
    form.setValue('createdAt', new Date());
    form.setValue('pin', undefined);
    form.setValue('orderNo', undefined);
  }, [form]);
};

export function useFindAndVerifyPickUpForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<FindAndVerifyPickUpFormValues>([
      simpleResolver<FindAndVerifyPickUpFormValues>(),
      yupResolver(validationSchema),
    ]),
    [validationSchema],
  );
  return useForm<FindAndVerifyPickUpFormValues>({
    resolver,
    mode: 'onSubmit',
    defaultValues: {
      createdAt: new Date(),
    },
  });
}
