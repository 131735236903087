import { useTranslation } from 'app/translations/useTranslation';
import { useUpdateUser } from 'pages/UserPage/state/useUpdateUser';
import { User, UserPermission } from 'pages/UserPage/User';
import React, { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useCompanies } from 'services/companies/state/useCompanies';
import { useRegions } from 'services/regions/state/useRegions';
import { PermissionManager } from './components/PermissionManager';
import { EditFormValues, useEditForm } from './hooks/useEditForm';
import * as Styled from './styled';
import { Select } from 'components/ReactHookForm/Select/Select';
import { AccountType } from 'api/OperatorApi';
import { ShowChangeOperatorPasswordModalAction } from '../ChangePasswordModal/ShowChangeOperatorPasswordModalAction';
import { ShowDeactivateUserModalAction } from '../DeactivateUserModal/ShowDeactivateUserModalAction';
import { useLocations } from '../../../../../../services/regions/state/hooks/useLocations';
import { ShowActivateUserModalAction } from '../ActivateUserModal/ShowActivateUserModalAction';

interface Props {
  user: User;
  onActiveStatusChange: VoidFunction;
}

export function UserEditor(props: Props) {
  const { t } = useTranslation('user');
  const { updateUser, isInProgress: isInProgressUpdateUser } = useUpdateUser();
  const { companies } = useCompanies();
  const { regions } = useRegions();
  const form = useEditForm({
    user: props.user,
    companies,
    regions,
  });
  const companyIds = Object.values(form.watch('assignedCompanyIds') ?? {}).filter(
    (companyId) => !!companyId,
  );
  const { fetchAllLocations, locationsAll } = useLocations(companies?.[0]?.id);
  useEffect(() => {
    fetchAllLocations();
  }, []);

  const filteredLocations = useMemo(() => {
    return locationsAll.filter((location) => {
      return companyIds.includes(location.companyId);
    });
  }, [companyIds, locationsAll]);
  return (
    <Styled.Box>
      <h1>{props.user.email}</h1>
      {props.user.accountType === AccountType.Deactivated ? (
        <ShowActivateUserModalAction
          onClose={props.onActiveStatusChange}
          operatorId={props.user.id}
        />
      ) : (
        <>
          <ShowChangeOperatorPasswordModalAction operatorId={props.user.id} />
          <ShowDeactivateUserModalAction
            onClose={props.onActiveStatusChange}
            operatorId={props.user.id}
          />
        </>
      )}
      {props.user.accountType !== AccountType.Deactivated && (
        <FormProvider {...form}>
          <Styled.Form
            onSubmit={form.handleSubmit((formValues: EditFormValues) => {
              updateUser({
                id: props.user.id,
                accountType: formValues.accountType,
                permissions: Object.values(formValues.permissions).filter(
                  (v) => !!v,
                ) as UserPermission[],
                assignedCompanyIds: Object.values(formValues.assignedCompanyIds).filter(
                  (value) => !!value,
                ) as string[],
                assignedRegionNames: Object.values(formValues.assignedRegionNames).includes('all')
                  ? ['*']
                  : (Object.values(formValues.assignedRegionNames).filter(
                      (value) => !!value,
                    ) as string[]),
                assignedLocations: Object.values(formValues.assignedLocations).filter(
                  (value) => !!value,
                ),
                canView: {
                  pinCode: !!formValues.canView.pinCode,
                  cancellation: !!formValues.canView.cancellation,
                  sensitiveData: !!formValues.canView.sensitiveData,
                  holdOrder: !!formValues.canView.holdOrder,
                  orderEstimations: !!formValues.canView.orderEstimations,
                  orderRoute: !!formValues.canView.orderRoute,
                  comments: !!formValues.canView.comments,
                  changeDriver: !!formValues.canView.changeDriver,
                  driverInfo: !!formValues.canView.driverInfo,
                  driverPayout: !!formValues.canView.driverPayout,
                },
              });
            })}
          >
            <Styled.SaveButton
              type={'submit'}
              dataCy={'button-update_user'}
              isInProgress={isInProgressUpdateUser}
            >
              {t('buttons.update')}
            </Styled.SaveButton>
            <Styled.DetailsBox>
              <Select
                name={'accountType'}
                label={t('labels.accountType')}
                values={Object.values(AccountType)
                  .filter((accountType) => accountType !== AccountType.Deactivated)
                  .map((accountType) => ({
                    value: accountType,
                    display: t(`labels.accountTypeValues.${accountType}`),
                  }))}
                defaultValue={props.user.accountType}
              />
            </Styled.DetailsBox>
            <PermissionManager
              companies={companies}
              regions={regions}
              locations={filteredLocations}
              errors={form.formState.errors}
              selectedRegions={form.watch('assignedRegionNames')}
              selectedCompanies={form.watch('assignedCompanyIds')}
            />
          </Styled.Form>
        </FormProvider>
      )}
    </Styled.Box>
  );
}
