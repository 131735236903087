import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import {
  combineResolvers,
  simpleResolver,
} from '../../../../../utlis/ReactHookForm/simpleResolver';

export type AddLocationFormValues = {
  name: string;
  street: string;
  city: string;
  zipCode: string;
  phone?: string;
  active?: string;
  defaultNote?: string;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object({
      name: yup.string().min(3).required(),
      street: yup.string().required(),
      city: yup.string().required(),
      zipCode: yup.string().required(),
      phone: yup
        .string()
        .optional()
        .matches(/^(((((\([+]?\d{1,3}\))|[+]\d{1,3})[ ]?)?\d([ ]?\d){8})|-)$/, t('pattern')),
    });
  }, [t]);
}

export const useAddLocationFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    form.setValue('name', '');
    form.setValue('street', '');
    form.setValue('city', '');
    form.setValue('zipCode', '');
    form.setValue('phone', '');
  }, [form]);
};

export function useAddLocationForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<AddLocationFormValues>([
      simpleResolver<AddLocationFormValues>(),
      yupResolver(validationSchema),
    ]),
    [validationSchema],
  );
  return useForm<AddLocationFormValues>({
    resolver,
    mode: 'onSubmit',
  });
}
