import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

import { useTranslation } from 'app/translations/useTranslation';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
// import { useDeactivateUser } from '../../../../state/useDeactivateUser';
import { useActivateUser } from '../../../../state/useActivateUser';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  operatorId: string;
}

export function ActivateUserModal(props: Props) {
  const { t } = useTranslation('user');
  const { activateUser, isInProgress } = useActivateUser();
  const onClick = async () => {
    await activateUser({
      operatorId: props.operatorId,
    });
    props.onClose();
  };
  return (
    <Dialog maxWidth="sm" open={props.isOpen}>
      <DialogTitle>{t('activateModal.title')}</DialogTitle>
      <DialogContent dividers>{t('activateModal.warningBody')}</DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('user:buttons.cancel')}</Button>
        <LoadingButton
          type="button"
          onClick={onClick}
          variant="contained"
          color="primary"
          disabled={isInProgress}
          loading={isInProgress}
        >
          {t('user:buttons.activate')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
