import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';

export interface ActiveStatusSelectProps {
  selectedActiveStatus: string | null;
  onActiveStatusChange: (activeStatus: string) => void;
}

export function ActiveStatusSelect(props: ActiveStatusSelectProps) {
  const { t } = useTranslation('user');

  return (
    <LabeledSelect
      id={'select-account-type'}
      onChange={MuiHandlers.Select.onChange((selectedValue) => {
        props.onActiveStatusChange(selectedValue);
      })}
      label={t('labels.accountType')}
      value={props.selectedActiveStatus}
    >
      <MenuItem value={'active'} key={'active'} data-label={t(`labels.activeStatus.active`)}>
        {t(`labels.activeStatus.active`)}
      </MenuItem>
      <MenuItem value={'inactive'} key={'inactive'} data-label={t(`labels.activeStatus.inactive`)}>
        {t(`labels.activeStatus.inactive`)}
      </MenuItem>
    </LabeledSelect>
  );
}
