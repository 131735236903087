import { Multiplier as ApiMultiplier } from 'api/MultiplierApi';
import { Multiplier } from '../model/Multiplier';

class Service {
  public mapToMultiplier(multiplier: ApiMultiplier): Multiplier {
    return {
      id: multiplier.id,
      description: multiplier.description,
      dateFrom: new Date(multiplier.dateFrom).getTime(),
      dateTo: new Date(multiplier.dateTo).getTime(),
      regions: multiplier.regions,
      companyIds: multiplier.companyIds,
      multiplier: multiplier.multiplier,
      createdBy: multiplier.createdBy,
      createdAt: new Date(multiplier.createdAt).getTime(),
      ...(multiplier.deactivatedAt && {
        deactivatedAt: new Date(multiplier.deactivatedAt).getTime(),
        deactivatedBy: multiplier.deactivatedBy,
        deactivationReason: multiplier.deactivationReason,
      }),
      ...(multiplier.timeRanges && { timeRanges: multiplier.timeRanges }),
    };
  }
}

export const ApiMultiplierMapper = new Service();
