import { useTranslation } from 'app/translations/useTranslation';
import { Input } from 'components/ReactHookForm/Input/Input';
import { useWarehouseAddStock } from 'pages/WarehousePage/state/useWarehouseAddStock';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useCreateForm, CreateFormValues } from './hooks/useCreateForm';
import * as Styled from './styled';
import { useProducts } from 'services/products/state/useProducts';
import { Warehouse } from 'pages/WarehousePage/Warehouse';

interface Props {
  warehouse: Warehouse;
}

export function WarehouseAddStock(props: Props) {
  const { t } = useTranslation('warehouse');
  const { warehouseAddStock, isInProgress: isInProgressWarehouseAddStock } = useWarehouseAddStock();
  const { products } = useProducts({ companyId: props.warehouse.companyId ?? undefined });

  const form = useCreateForm();
  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.Form
          onSubmit={form.handleSubmit((formValues: CreateFormValues) => {
            warehouseAddStock({
              stock: formValues,
              warehouseId: props.warehouse.id,
            });
          })}
        >
          <Styled.Table>
            <thead>
              <tr>
                <th>{t('stockAdd.list.headers.name')}</th>
                <th>{t('stockAdd.list.headers.quantity')}</th>
                <th>{t('stockAdd.list.headers.newQuantity')}</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => {
                return (
                  <tr key={product.id}>
                    <td>{product.name}</td>
                    <td>{props.warehouse.stock?.[product.id]?.amount || 0}</td>
                    <td>
                      <Input
                        name={`${product.id}.stock`}
                        type={'number'}
                        label={t('stockAdd.list.headers.newQuantity')}
                        dataCy={`input-product-${product.id}`}
                      />
                      <Input
                        name={`${product.id}.batchNo`}
                        type={'text'}
                        label={t('stockAdd.list.headers.batchNumber')}
                        dataCy={`input-product-${product.id}`}
                      />
                      <br />
                      <hr />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Styled.Table>
          <Styled.SaveButton
            type={'submit'}
            dataCy={'button-create_user'}
            isInProgress={isInProgressWarehouseAddStock}
          >
            {t('buttons.addStock')}
          </Styled.SaveButton>
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
