import React, { useMemo } from 'react';

import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Styled from './styled';
import LoadingButton from '@mui/lab/LoadingButton';

import { AsyncStatus } from 'utlis/State';
import { useTranslation } from 'app/translations/useTranslation';

import { Input } from 'components/ReactHookForm/Input/Input';

import {
  PickUpVerificationFormValues,
  usePickUpVerificationForm,
} from './hooks/usePickUpVerificationForm';

import { useVerifyPickUp } from '../../../../../../../../../../state/useVerifyPickUp';

interface Props {
  orderId: string;
}

export function PickUpVerificationForm(props: Props) {
  const { t } = useTranslation('order');
  const { verifyOrderPickUp, verifyOrderPickUpStatus } = useVerifyPickUp();

  const form = usePickUpVerificationForm();

  const onSubmit: SubmitHandler<PickUpVerificationFormValues> = (formValues) => {
    verifyOrderPickUp({
      pin: formValues.pin,
      orderId: props.orderId,
    });
  };

  const onReset = () => {
    form.reset();
  };

  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.Form
          onSubmit={form.handleSubmit<PickUpVerificationFormValues>(onSubmit)}
          onReset={onReset}
        >
          <Styled.DetailsBox>
            <Input
              name={'pin'}
              label={t('pickUpVerification.form.fields.pin')}
              dataCy={'input-pin'}
            />
          </Styled.DetailsBox>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={verifyOrderPickUpStatus === AsyncStatus.Pending}
            loading={verifyOrderPickUpStatus === AsyncStatus.Pending}
          >
            {t('pickUpVerification.form.submitButton')}
          </LoadingButton>
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
