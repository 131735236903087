import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as Slice from '../../state/slice';
import { DriverSelect } from './components/DriverSelect';
import { Grid } from '@mui/material';
import { EntitySelect } from './components/EntitySelect';
import { WarehouseSelect } from './components/WarehouseSelect';
import { useFilters } from '../../state/useFilters';

interface Props {}

export function Filters(props: Props) {
  const { filters, changeFilters } = useFilters();
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item>
          <EntitySelect
            target={'givingType'}
            onEntityChange={(entity) => {
              changeFilters({ givingType: entity });
            }}
          />
          {filters.givingType === 'warehouse' && (
            <WarehouseSelect
              target={'givingWarehouse'}
              omitWarehouseId={null}
              onWarehouseChange={(warehouseId) => {
                changeFilters({ givingWarehouseId: warehouseId });
              }}
              selectedWarehouseId={filters.givingWarehouseId}
            />
          )}
          {filters.givingType !== 'warehouse' && (
            <DriverSelect
              target={'givingDriver'}
              omitDriverId={null}
              onDriverChange={(driverId) => {
                changeFilters({ givingDriverId: driverId });
              }}
              selectedDriverId={filters.givingDriverId}
            />
          )}
        </Grid>
        <Grid item>
          <EntitySelect
            target={'receivingType'}
            onEntityChange={(entity) => {
              changeFilters({ receivingType: entity });
            }}
          />
          {filters.receivingType === 'warehouse' && (
            <WarehouseSelect
              target={'receivingWarehouse'}
              omitWarehouseId={null}
              onWarehouseChange={(warehouseId) => {
                changeFilters({ receivingWarehouseId: warehouseId });
              }}
              selectedWarehouseId={filters.receivingWarehouseId}
            />
          )}
          {filters.receivingType !== 'warehouse' && (
            <DriverSelect
              omitDriverId={filters.givingDriverId}
              target={'receivingDriver'}
              onDriverChange={(driverId) => {
                changeFilters({ receivingDriverId: driverId });
              }}
              selectedDriverId={filters.receivingDriverId}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
`,
);

const FilterBox = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
