import { ApiRequestSaga, SimpleGenerator } from './Request/ApiRequestSaga';
import { Location, LocationList } from 'model/Location';
import { Region } from 'model/Region';
import { getRequest, postRequest } from './Request/ApiRequest';
import { ZipCodesResponse } from '../pages/ZipCodesPage/model/ZipCode';

interface ListRegionsResponse {
  list: Region[];
}

interface AddLocationParams {
  companyId: string;
  location: Partial<Location>;
}
interface UpdateLocationParams {
  locationId: string;
  location: Partial<Location>;
}

interface GetZipCodesParams {
  radius: number;
  street: string;
  city: string;
  zipCode: string;
}

const listLocations = (query: {
  companyIds?: string[];
  isActive?: boolean;
}): SimpleGenerator<LocationList> => {
  return ApiRequestSaga.Get<LocationList>({
    endpoint: '/region/locations',
    query: {
      companyId: query.companyIds,
      // active: query.isActive ,
    },
  });
};

const listActiveLocationsByCompanyId = (companyId?: string): SimpleGenerator<LocationList> => {
  if (!companyId) {
    return listLocations({ isActive: true });
  }
  return listLocations({ companyIds: [companyId], isActive: true });
};

export const RegionApiSaga = {
  listRegions: (query: { isActive: boolean }) => {
    return ApiRequestSaga.Get<ListRegionsResponse>({
      endpoint: '/region/list',
      query: {
        active: query.isActive,
      },
    });
  },
  listLocations,
  listActiveLocationsByCompanyId,
  updateLocation: (params: UpdateLocationParams) =>
    postRequest<void>({
      endpoint: `/region/locations/update`,
      data: params,
    }),
  addLocation: (params: AddLocationParams) =>
    postRequest<void>({
      endpoint: '/region/locations/add',
      data: {
        companyId: params.companyId,
        ...params.location,
      },
    }),
  getZipCodes: (params: GetZipCodesParams) => {
    return ApiRequestSaga.Get<ZipCodesResponse>({
      endpoint: '/region/zip-codes',
      query: {
        radius: params.radius,
        street: params.street,
        city: params.city,
        zipCode: params.zipCode,
      },
    });
  },
};
