import { ApiRequestSaga } from './Request/ApiRequestSaga';
import { StockItemInfo } from '../pages/WarehousePage/Warehouse';
import { SimplifiedStock } from '../pages/StockPage/Stock';

export interface ApiWarehouse {
  id: string;
  companyId: string;
  region: string;
  owner: string;
  regionId: string;
  stock?: {
    [key: string]: SimplifiedStock;
  };
}

export interface WarehouseAddStockParams {
  warehouseId: string;
  productId: string;
  amount: number;
}

export type ListWarehousesResponse = ApiWarehouse[];

interface ListWarehousesParams {
  companyId?: string;
}

export const WarehouseApiSaga = {
  listWarehouses: (params: ListWarehousesParams) => {
    return ApiRequestSaga.Get<ListWarehousesResponse>({
      endpoint: '/warehouse/list',
      query: {
        ...(params.companyId && { companyIds: [params.companyId] }),
      },
    });
  },
  warehouseAddStock: (params: WarehouseAddStockParams) => {
    return ApiRequestSaga.Post({
      endpoint: '/warehouse/add',
      data: {
        ...params,
      },
    });
  },
};
