import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as Styled from './styled';
import LoadingButton from '@mui/lab/LoadingButton';
import { AsyncStatus } from 'utlis/State';
import { useTranslation } from 'app/translations/useTranslation';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Grid } from '@mui/material';
import { TimeSelect } from '../../../../../components/TimeSelect';
import TextField from '@mui/material/TextField';
import { PrimaryButton } from '../../../../../components/Button/Buttons';
import TrashIcon from '@mui/icons-material/DeleteForever';
import { TimeRange } from '../../../../MultiplierPage/components/CreateMultiplier/CreateMultiplier';
import { useCreateMultiplierForm } from '../../../../MultiplierPage/components/CreateMultiplier/hooks/useCreateMultiplierForm';
import { getHours, getMinutes, isDate } from 'date-fns';
import { DayWeekSettings, WeekDay } from './WeeklyCalendar';

interface Props {
  weekDay: WeekDay;
  settings: DayWeekSettings;
  onChange: (weekDay: WeekDay, settings: DayWeekSettings) => void;
}

export function WeeklyCalendarDayRow({ weekDay, settings, onChange }: Props) {
  const { t } = useTranslation('settings');
  const isTimeRangeCorrect = (settings: DayWeekSettings) => {
    return (
      !!settings?.from?.fullDate &&
      !!settings?.to?.fullDate &&
      settings.from.fullDate < settings.to.fullDate
    );
  };

  const setDayWeekOpenAt = (value: Date | null) => {
    if (!!value && isDate(value)) {
      const newSettings = {
        ...settings,
        from: {
          // @ts-ignore
          hour: getHours(value),
          // @ts-ignore
          minute: getMinutes(value),
          fullDate: value,
        },
      };
      onChange(weekDay, newSettings);
    }
  };

  const setDayWeekCloseAt = (value: Date | null) => {
    if (!!value && isDate(value)) {
      const newSettings = {
        ...settings,
        to: {
          // @ts-ignore
          hour: getHours(value),
          // @ts-ignore
          minute: getMinutes(value),
          fullDate: value,
        },
      };
      onChange(weekDay, newSettings);
    }
  };

  const setDayWeekIsOpen = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSettings = {
      ...settings,
      isOpen: event.target.checked,
    };
    onChange(weekDay, newSettings);
  };

  const form = useFormContext();

  return (
    <Grid
      container
      alignItems={'center'}
      spacing={1}
      key={`timeRange`}
      style={
        isTimeRangeCorrect(settings)
          ? { borderBottom: '2px solid rgb(141 204 141)', paddingBottom: 10 }
          : { marginBottom: 10 }
      }
    >
      <Grid item xs={2}>
        <h3>{t(`locations.weekDays.${weekDay}`)}</h3>
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Switch checked={settings.isOpen} onChange={setDayWeekIsOpen} color="secondary" />
          }
          label={settings.isOpen ? 'Otwarte' : 'Zamknięte'}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <TimeSelect
          disabled={!settings.isOpen}
          label={t('locations.openFrom')}
          onChange={(newValue) => setDayWeekOpenAt(newValue)}
          name={`timeRange.${weekDay}.from`}
          fieldState={form.getFieldState(`timeRange`, form.formState)}
          value={settings?.from?.fullDate ?? null}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <TimeSelect
          disabled={!settings.isOpen}
          label={t('locations.closeAt')}
          onChange={(newValue) => setDayWeekCloseAt(newValue)}
          name={`timeRange.${weekDay}.from`}
          fieldState={form.getFieldState(`timeRange`, form.formState)}
          value={settings?.to?.fullDate ?? null}
        />
      </Grid>
    </Grid>
  );
}
