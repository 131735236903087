import { useForm } from 'react-hook-form';

export type CreateFormValues = {
  [productId: string]: {
    batchNo: string;
    stock: string;
  };
};

export function useCreateForm() {
  const form = useForm<CreateFormValues>({
    mode: 'onSubmit',
  });

  return form;
}
