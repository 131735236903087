import { WorkingSettings } from '../utlis/workingSettings/model/WorkingSettings';
import { PackageSize } from '../pages/Orders/model/Order';

export interface Company {
  id: string;
  name: string;
  active?: boolean;
  imageFile: string;
  countryCode: string;
  deliverySettings?: {
    cancelOrderAfter?: number;
    maxDeliveryDistanceRadius?: number;
    groupPickupPointOrders?: number;
    groupedOrdersMaxDistance?: number;
    maxDestinationDistanceRadius?: number;
    offersLimit?: {
      limit: number;
      hoursRange: [number, number];
    }[];
    showOnOrderMarketAfter?: number;
    scheduledAtToTimeSlots?: boolean;
  };
  workingSettings?: WorkingSettings;
  payout: unknown;
  mobileAppSettings: unknown;
  orderSettings?: {
    maxOrderProductsAllowed?: number;
    scheduledAtToScheduledRange?: boolean;
    // TODO: add other supported fields
    orderFormFields?: FormField[];
    showDeliveryEstimationOnForm?: boolean;
    allowedPayments?: AllowedPayment[];
    showOnOrderMarket?: boolean;
    orderFormSuggestedProducts?: string[];
    defaultPackageSize?: PackageSize;
  };
  contractData?: ContractData;
  contract?: Contract;
  activatedAt?: string;
  status: CompanyStatus;
  owner?: CompanyOwner;
  createdAt?: string;
}

export interface CompanyOwner {
  email: string;
  name: string;
  phone?: string;
  city?: string;
  deliveryProductType?: string;
}

export enum CompanyStatus {
  Active = 'active',
  Pending = 'pending',
  Inactive = 'inactive',
}

export interface Contract {
  generatedAt?: Date;
  generetedBy?: string;
  acceptedAt?: Date;
  data?: ContractData;
}

export interface ContractData {
  name: string;
  street: string;
  headquartersIn: string;
  zipCode: string;
  city: string;
  nip: string;
  regon: string;
  firstName: string;
  lastName: string;
  // termsOfServiceAccepted: boolean;
}

export type FormField = 'externalId' | 'pickupPoint.paymentDetails';

export type AllowedPayment = {
  paymentType: 'epruf_code' & PaymentType;
  displayAs: DisplayTypes;
};

export type PaymentType = 'generic_prepaid' | 'epruf_code';

export type DisplayTypes = 'qrcode';
