import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccountTypeSuggestedPermissions,
  User,
  UserCanView,
  UserPermission,
} from 'pages/UserPage/User';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import {
  CheckboxAtLeastOneSelectedTest,
  CheckboxesObjectAtLeastOneSelectedTest,
} from 'app/validation/validationTests';
import { AccountType } from 'api/OperatorApi';
import { Company } from '../../../../../../../model/Company';
import { Region } from '../../../../../../../model/Region';

export type CreateFormValues = {
  accountType: AccountType;
  email: string;
  password: string;
  permissions: { [key: string]: UserPermission };
  assignedCompanyIds: { [key: string]: string };
  assignedRegionNames: { [key: string]: string };
  assignedLocations: { [key: string]: string };
  passwordConfirmation: string;
  canView: {
    [key: string]: string;
  };
};

function useValidationSchema() {
  const { t } = useTranslation('user');
  return useMemo(() => {
    return yup.object({
      email: yup.string().required().email(),
      password: yup.string().required().min(7),
      accountType: yup.string().required(),
      passwordConfirmation: yup
        .string()
        .required()
        .oneOf([yup.ref('password')], t('fields.validation.passwordNotTheSame')),
      assignedCompanyIds: yup
        .object()
        .required()
        .test(...CheckboxesObjectAtLeastOneSelectedTest),
      assignedRegionNames: yup
        .object()
        .required()
        .test(...CheckboxesObjectAtLeastOneSelectedTest),
      permissions: yup
        .object()
        .required()
        .shape(
          Object.values(UserPermission).reduce<{ [key: string]: any }>((shape, permission) => {
            shape[permission.replace('.', '_')] = yup.string().nullable();
            return shape;
          }, {}),
        )
        .test(...CheckboxesObjectAtLeastOneSelectedTest),
      canView: yup
        .object()
        .shape({
          pinCode: yup.boolean().nullable().optional(),
          cancellation: yup.boolean().nullable().optional(),
          sensitiveData: yup.boolean().nullable().optional(),
          holdOrder: yup.boolean().nullable().optional(),
          orderEstimations: yup.boolean().nullable().optional(),
          orderRoute: yup.boolean().nullable().optional(),
          comments: yup.boolean().nullable().optional(),
          changeDriver: yup.boolean().nullable().optional(),
          driverInfo: yup.boolean().nullable().optional(),
          driverPayout: yup.boolean().nullable().optional(),
        })
        .required(),
    });
  }, [t]);
}

export function useCreateForm() {
  const validationSchema = useValidationSchema();
  const form = useForm<CreateFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { reset: resetForm, setValue, watch } = form;
  const accountType = watch('accountType');
  const defaultAccountTypePermissions: { [key: string]: boolean } =
    AccountTypeSuggestedPermissions[accountType]?.canView ?? {};

  useEffect(() => {
    setValue('canView.sensitiveData', defaultAccountTypePermissions?.sensitiveData?.toString());
    setValue('canView.pinCode', defaultAccountTypePermissions?.pinCode?.toString());
    setValue('canView.cancellation', defaultAccountTypePermissions?.cancellation?.toString());
    setValue('canView.holdOrder', defaultAccountTypePermissions?.holdOrder?.toString());
    setValue(
      'canView.orderEstimations',
      defaultAccountTypePermissions?.orderEstimations?.toString(),
    );
    setValue('canView.orderRoute', defaultAccountTypePermissions?.orderRoute?.toString());
    setValue('canView.comments', defaultAccountTypePermissions?.comments?.toString());
    setValue('canView.changeDriver', defaultAccountTypePermissions?.changeDriver?.toString());
    setValue('canView.driverInfo', defaultAccountTypePermissions?.driverInfo?.toString());
    setValue('canView.driverPayout', defaultAccountTypePermissions?.driverPayout?.toString());
  }, [resetForm, setValue, accountType]);

  return form;
}
