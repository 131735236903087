import { OrderApiSaga } from 'api/OrderApi';
import { takeLatest, debounce, takeLeading } from 'redux-saga/effects';
import { Socket, SocketEvent, SocketEventPayload } from 'services/sockets';
import { call, put, select } from 'typed-redux-saga';
import { PA, AsyncStatus } from 'utlis/State';
import { ApiOrderLocationHistoryMapper } from './ApiOrderLocationHistoryMapper';
import { ApiOrderMapper } from './ApiOrderMapper';
import * as Slice from './slice';
import FileSaver from 'file-saver';
import { addErrorToast, addSuccessToast } from 'services/toast';
import { translate } from 'app/translations/useTranslation';

function* fetchOrderList() {
  try {
    const filters = yield* select(Slice.selectors.makeSelectOrderListFilters());
    const response = yield* OrderApiSaga.listOrders({
      globalIdSearch: filters.searchPhrase,
      status: filters.statuses.length !== 0 ? filters.statuses.join(',') : undefined,
      dateFrom: filters.createdAt[0],
      dateTo: filters.createdAt[1],
      companyIds: filters.companyId !== null ? [filters.companyId] : [],
      region: filters.region ?? undefined,
      productId: filters.productId ?? undefined,
    });
    yield put(
      Slice.actions.fetchOrderListSuccess({
        orders: response.orders.map((order) => ApiOrderMapper.mapToOrder(order)),
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.fetchOrderListError());
  }
}

function* downloadOrderLabel({ payload }: PA<Slice.ActionTypes.DownloadOrderLabel>) {
  try {
    const file = yield* OrderApiSaga.getOrderLabel(payload.orderId);
    FileSaver.saveAs(file, `${payload.orderId}_order_label.pdf`);
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('order', 'notifications.downloadLabelFailure'),
    });
  }
  yield put(Slice.actions.downloadOrderLabelFinished());
}

function* triggerFetchOrderList() {
  yield put(Slice.actions.fetchOrderList());
}

function* triggerBackgroundFetchOrderList() {
  yield put(Slice.actions.fetchOrderListInBackground());
}

function* fetchXDelivererRoute({ payload }: PA<Slice.ActionTypes.FetchXDelivererRoute>) {
  const { orderId } = payload;

  try {
    const history = yield* OrderApiSaga.fetchOrderLocationHistory(orderId);

    yield put(
      Slice.actions.fetchXDelivererRouteSuccess({
        route: ApiOrderLocationHistoryMapper.mapToRoute(history),
      }),
    );
  } catch (e) {
    console.log(e);
    yield put(Slice.actions.fetchXDelivererRouteError());
  }
}

function* addXDelivererRouteLastLocation(
  payload: SocketEventPayload[SocketEvent.DriverLocationChange],
) {
  const route = yield* select(Slice.selectors.makeSelectXDelivererRoute());
  if (route.status === AsyncStatus.Success && route.data.orderId === payload.orderId) {
    yield put(
      Slice.actions.addXDelivererRouteHistoryRecord({
        location: { lng: payload.lon, lat: payload.lat },
      }),
    );
  }
}

function* cancelOrder({ payload }: PA<Slice.ActionTypes.CancelOrder>) {
  try {
    yield* OrderApiSaga.cancelOrder(payload);
    yield call(addSuccessToast, {
      message: translate('order', 'notifications.orderCancelled'),
    });
  } catch (e) {
    const code = e?.response?.errorCode || 'unknown';
    yield call(addErrorToast, {
      id: 'order_cancel_failure',
      message: translate(
        'order',
        `cancelOrder.errors.${code}`,
        translate('order', 'cancelOrder.errors.unknown'),
      ),
    });
    console.error(e);
  }
  yield put(Slice.actions.cancelOrderSuccess());
}

function* changeOrderDriver({ payload }: PA<Slice.ActionTypes.ChangeOrderDriver>) {
  try {
    yield* OrderApiSaga.changeOrderDriver(payload);
    yield call(addSuccessToast, {
      message: translate('order', 'notifications.orderDriverChanged'),
    });
  } catch (e) {
    const code = e?.response?.errorCode || 'unknown';
    yield call(addErrorToast, {
      id: 'change_order_driver_failure',
      message: translate(
        'order',
        `changeOrderDriver.errors.${code}`,
        translate('order', 'changeOrderDriver.errors.unknown'),
      ),
    });
    console.error(e);
    yield put(Slice.actions.changeOrderDriverError());
  }
  yield put(Slice.actions.changeOrderDriverSuccess());
}

function* verifyOrderPickUp({ payload }: PA<Slice.ActionTypes.VerifyPickUp>) {
  try {
    yield* OrderApiSaga.verifyOrderPickUp(payload);
    yield call(addSuccessToast, {
      message: translate('order', 'notifications.pickUpVerified'),
    });
  } catch (e) {
    const code = e?.response?.errorCode || 'incorrect_verification_code';
    yield call(addErrorToast, {
      id: 'pick_up_verification_failure',
      message: translate(
        'order',
        `pickUpVerification.errors.${code}`,
        translate('order', 'pickUpVerification.errors.unknown'),
      ),
    });
    console.error(e);
    yield put(Slice.actions.verifyOrderPickUpError());
  }
  yield put(Slice.actions.verifyOrderPickUpSuccess());
}

function* findAndVerifyOrderPickUp({ payload }: PA<Slice.ActionTypes.FindAndVerifyPickUp>) {
  try {
    yield* OrderApiSaga.findAndVerifyOrderPickUp(payload);
    yield call(addSuccessToast, {
      message: translate('order', 'notifications.pickUpVerified'),
    });
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; // months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const orderNo = `${day.toString().padStart(2, '0')}${month
      .toString()
      .padStart(2, '0')}${year}/${payload.orderNo}`;

    yield put(Slice.actions.findAndVerifyOrderPickUpSuccess({ orderNo: orderNo }));
  } catch (e) {
    const code = e?.response?.errorCode || 'incorrect_verification_code';
    yield call(addErrorToast, {
      id: 'pick_up_verification_failure',
      message: translate(
        'order',
        `pickUpVerification.errors.${code}`,
        translate('order', 'pickUpVerification.errors.unknown'),
      ),
    });
    console.error(e);
    yield put(Slice.actions.findAndVerifyOrderPickUpError());
  }
}

function* holdOrder({ payload }: PA<Slice.ActionTypes.HoldOrder>) {
  try {
    yield* OrderApiSaga.setOnHoldOrder(payload);
    yield call(addSuccessToast, {
      message: translate('order', 'notifications.orderHeld'),
    });
    yield triggerBackgroundFetchOrderList();
  } catch (e) {
    const code = e?.response?.errorCode || 'unknown';
    yield call(addErrorToast, {
      id: 'order_hold_failure',
      message: translate(
        'order',
        `onHoldOrder.errors.${code}`,
        translate('order', 'onHoldOrder.errors.unknown'),
      ),
    });
    console.error(e);
    yield put(Slice.actions.holdOrderError());
  }
  yield put(Slice.actions.holdOrderSuccess());
}

function* releaseHeldOrder({ payload }: PA<Slice.ActionTypes.HoldOrder>) {
  try {
    yield* OrderApiSaga.releaseOnHoldOrder(payload);
    yield call(addSuccessToast, {
      message: translate('order', 'notifications.orderReleased'),
    });
    yield triggerBackgroundFetchOrderList();
  } catch (e) {
    const code = e?.response?.errorCode || 'unknown';
    yield call(addErrorToast, {
      id: 'order_release_failure',
      message: translate(
        'order',
        `releaseHeldOrder.errors.${code}`,
        translate('order', 'releaseHeldOrder.errors.unknown'),
      ),
    });
    console.error(e);
    yield put(Slice.actions.releaseHeldOrderError());
  }
  yield put(Slice.actions.releaseHeldOrderSuccess());
}

function* addOrderComment({ payload }: PA<Slice.ActionTypes.AddOrderComment>) {
  try {
    yield* OrderApiSaga.addComment(payload);
    yield call(addSuccessToast, {
      message: translate('order', 'notifications.orderCommentAdded'),
    });
    yield triggerBackgroundFetchOrderList();
  } catch (e) {
    const code = e?.response?.errorCode || 'unknown';
    yield call(addErrorToast, {
      id: 'add_comment_failure',
      message: translate(
        'order',
        `addComment.errors.${code}`,
        translate('order', 'addComment.errors.unknown'),
      ),
    });
    console.error(e);
    yield put(Slice.actions.addOrderCommentError());
  }
  yield put(Slice.actions.addOrderCommentSuccess());
}

export function* saga() {
  yield takeLatest(Slice.actions.fetchOrderList, fetchOrderList);
  yield takeLatest(Slice.actions.downloadOrderLabel, downloadOrderLabel);
  yield takeLatest(Slice.actions.cancelOrder, cancelOrder);
  yield takeLatest(Slice.actions.holdOrder, holdOrder);
  yield takeLatest(Slice.actions.releaseHeldOrder, releaseHeldOrder);
  yield takeLatest(Slice.actions.addOrderComment, addOrderComment);
  yield takeLeading(Slice.actions.fetchOrderListInBackground, fetchOrderList);
  yield takeLatest(Slice.actions.setFilters, triggerFetchOrderList);
  yield takeLatest(Slice.actions.fetchXDelivererRoute, fetchXDelivererRoute);
  yield takeLatest(Slice.actions.changeOrderDriver, changeOrderDriver);
  yield takeLatest(Slice.actions.verifyOrderPickUp, verifyOrderPickUp);
  yield takeLatest(Slice.actions.findAndVerifyOrderPickup, findAndVerifyOrderPickUp);
  yield debounce(500, Slice.actions.setSearchPhrase, triggerFetchOrderList);
  Socket.on(SocketEvent.AcceptOrder, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.FinishOrder, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.CancelOrder, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.RenewOrder, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.NewOrder, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.EditOrder, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.DelayingDelivery, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.DeliverOrder, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.ArriveOrder, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.NotAcceptedOrder, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.CancelledOrderClientContacted, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.DeactivatedOrderMultiplier, triggerBackgroundFetchOrderList);
  Socket.on(SocketEvent.DriverLocationChange, addXDelivererRouteLastLocation);
}
