import { translate } from 'app/translations/useTranslation';
import { takeLeading } from 'redux-saga/effects';
import { addSuccessToast, addErrorToast } from 'services/toast';
import { call, put, select } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { WalletApiSaga } from 'api/WalletApi';
import * as Slice from './slice';
import { RegionApiSaga } from 'api/RegionApi';

import { add, endOfMonth, startOfMonth, sub } from 'date-fns';
import { ApiMultiplierMapper } from '../../MultiplierPage/state/ApiMultiplierMapper';

function* getZipCodes({ payload }: PA<Slice.ActionTypes.GetZipCodes>) {
  try {
    const response = yield* RegionApiSaga.getZipCodes({
      radius: payload.radius,
      street: payload.street,
      city: payload.city,
      zipCode: payload.zipCode,
    });
    yield put(
      Slice.actions.getZipCodesSuccess({
        zipCodes: response.zipCodes,
        centerPoint: response.centerPoint ?? null,
        zipCodesOutOfBounds: response.zipCodesOutOfBounds ?? [],
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.getZipCodesError());
  }
}
export function* saga() {
  yield takeLeading(Slice.actions.getZipCodes, getZipCodes);
}
