import React, { useCallback, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { default as MuiTableContainer } from '@mui/material/TableContainer';
import { User } from 'pages/UserPage/User';
import { TableBody } from './components/TableBody';
import { TableHeaders } from './components/TableHeaders';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import TableFiltersProvider from '../../../../../../components/dataTables/filters/TableFiltersProvider';
import { Filters } from '../../../../../Orders/OrderPage/components/Filters/Filters';
import TableFiltersToolbar from '../../../../../../components/dataTables/filters/TableFiltersToolbar';
import useMUIDataTableDefaultOptions from '../../../../../../utlis/MUIDataTables/useMUIDataTableDefaultOptions';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { OrderRowDetails } from '../../../../../Orders/OrderPage/components/OrderList/components/Row/components/OrderRowDetails/OrderRowDetails';
import { compact, filter, find } from 'lodash';
import { Company } from '../../../../../../model/Company';
import CompanyImage from '../../../../../Companies/coponents/CompanyImage';
import { OnHold, OrderComment, OrderStage, XDeliverer } from '../../../../../Orders/model/Order';
import { Multiplier } from '../../../../../MultiplierPage/model/Multiplier';
import Tooltip from '@mui/material/Tooltip';
import OnHoldIcon from '../../../../../../components/Icons/OnHoldIcon';
import PackageWarning from '../../../../../../components/Icons/PackageWarning';
import DialpadOff from '../../../../../../components/Icons/DialpadOff';
import { useTranslation } from '../../../../../../app/translations/useTranslation';

export interface UserTableProps {
  users: User[];
  isLoading: boolean;
  selectedUser: User | null;
  onSelectedUserChange: (user: User | null) => void;
}

export function UserTable(props: UserTableProps) {
  const { t } = useTranslation('user');
  const defaultOptions = useMUIDataTableDefaultOptions();
  const selectUser = useCallback(
    (index: number) => {
      props.onSelectedUserChange(props.users[index]);
    },
    [props.users],
  );
  const options = useMemo<MUIDataTableOptions>(
    () => ({
      ...defaultOptions,
      filter: false,
      search: true,
      searchOpen: true,
      searchAlwaysOpen: true,
      viewColumns: false,
      onRowClick: (rowData, rowMeta) => {
        selectUser(rowMeta.dataIndex);
      },
    }),
    [defaultOptions, selectUser],
  );

  const columns = useMemo<MUIDataTableColumn[]>(
    () => [
      {
        name: 'email',
        label: t('list.headers.email'),
      },
    ],
    [t],
  );

  return (
    <MUIDataTable
      title=""
      data={props.users}
      columns={columns}
      options={options}
      components={{ TableToolbar: TableFiltersToolbar }}
    />
  );
  return (
    <TableContainer>
      <Table stickyHeader={true}>
        <TableHeaders />
        <TableBody
          users={props.users}
          hasError={false}
          isLoading={props.isLoading}
          onReload={() => {}}
          onUserClick={(user) => props.onSelectedUserChange(user)}
          activeUser={props.selectedUser}
        />
      </Table>
    </TableContainer>
  );
}

const TableContainer = styled(MuiTableContainer)`
  height: 65vh;
`;
