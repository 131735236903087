import { useTranslation } from 'app/translations/useTranslation';
import Grid from '@mui/material/Grid';

import Page from 'components/Page/Page';
import { Card } from '@mui/material';

// Core libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal components
import ZipCodesMap from './components/ZipCodesMap';
import ZipCodeControls from './components/ZipCodeControls';
import { ZipCode, ZipCodesResponse } from './model/ZipCode';
import { useZipCodes } from './state/useZipCodes';
import { Path } from 'history';
import { useLocation } from 'react-router';
interface Props {}

export function ZipCodesPage(props: Props) {
  const { t } = useTranslation('wallet');
  const styles = useStyles();
  const { state } = useLocation();

  // const [zipCodes, setZipCodes] = useState<ZipCodesResponse | undefined>(undefined);
  const zipCodes = useZipCodes();
  const [locationForm, setLocationForm] = useState<{
    radius: number;
    street?: string;
    city?: string;
    zipCode?: string;
    forceFetch?: boolean;
  }>({
    radius: 7,
  });

  const [showMarkers, setShowMarkers] = useState<boolean>(true);
  const [showAreas, setShowAreas] = useState<boolean>(false);

  const fetchZipCodes = useCallback(async () => {
    try {
      await zipCodes.getZipCodes({
        street: locationForm.street,
        city: locationForm.city,
        zipCode: locationForm.zipCode,
        radius: locationForm.radius,
      });
    } catch (error) {}
  }, [locationForm]);

  useEffect(() => {
    //@ts-ignore
    if (state && state?.city && state?.street && state?.zipCode) {
      setLocationForm({
        ...locationForm,
        // @ts-ignore
        street: state.street,
        // @ts-ignore
        city: state.city,
        // @ts-ignore
        zipCode: state.zipCode,
        forceFetch: true,
      });
    }
  }, [state]);
  useEffect(() => {
    if (locationForm.forceFetch) {
      if (locationForm.city && locationForm.street && locationForm.zipCode) {
        fetchZipCodes();
        setLocationForm({ ...locationForm, forceFetch: false });
        window.history.replaceState({}, document.title);
      } else {
        setLocationForm({ ...locationForm, forceFetch: false });
        window.history.replaceState({}, document.title);
      }
    }
  }, [locationForm]);
  const zipCodesListing = useMemo(() => {
    return zipCodes.zipCodeListing.list;
  }, [zipCodes.zipCodeListing]);

  const zipCodesOutOfBoundsListing = useMemo(() => {
    return zipCodes.zipCodeListing.listOutOfBounds;
  }, [zipCodes.zipCodeListing]);

  return (
    <Page>
      <Page.Header>Kody pocztowe</Page.Header>
      <Card>
        <div className={styles.mainContainer}>
          <div className={styles.leftPaneContainers}>
            <ZipCodeControls
              showMarkers={showMarkers}
              setShowMarkers={setShowMarkers}
              showAreas={showAreas}
              setShowAreas={setShowAreas}
              formData={locationForm}
              setFormData={setLocationForm}
              submitFormData={fetchZipCodes}
              loading={zipCodes.zipCodeListing.isLoading}
            />
            <div className={styles.message}>
              Kody:{' '}
              {zipCodes.zipCodeListing.isLoading && <CircularProgress size={16} thickness={6} />}
              {!zipCodes.zipCodeListing.isLoading &&
                zipCodesListing.map((zipCode, idx) => {
                  const last = idx === zipCodesListing.length - 1;
                  return (
                    <React.Fragment key={idx}>
                      <span>{zipCode.name}</span>
                      {!last && ',\n'}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
          <div className={styles.mapContainer}>
            <ZipCodesMap
              showMarkers={showMarkers}
              showAreas={showAreas}
              zipCodes={zipCodesListing || []}
              lat={zipCodes.centerPoint?.lat}
              lng={zipCodes.centerPoint?.lng}
              extraZipCodes={zipCodesOutOfBoundsListing || []}
              radius={locationForm.radius || 0}
            />
          </div>
        </div>
      </Card>

      <Card style={{ minHeight: 500 }}></Card>
    </Page>
  );
}

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    leftPaneContainers: {
      display: 'flex',
      flexDirection: 'column',
    },
    root: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    topBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    message: {
      margin: theme.spacing(2),
    },
    logo: {
      height: 'auto',
      width: (32 * 600) / 58,
      maxWidth: '90%',
    },
    mapContainer: {
      flexGrow: 1,
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
  }),
);
