import { createSelector, createSlice } from '@reduxjs/toolkit';
import { endOfToday, startOfToday } from 'date-fns';
import { orderBy } from 'lodash';
import { AsyncStatus, PA } from 'utlis/State';
import type { Order } from '../../model/Order';
import { Coordinates, Route } from '../../model/Route';

interface Filters {
  searchPhrase: string;
  statuses: string[];
  createdAt: [Date, Date];
  companyId: string | null;
  region: string | null;
  productId: string | null;
}

export interface State {
  orderList: {
    filters: Filters;
    data: Order[];
    status: AsyncStatus;
  };
  xDelivererRoute:
    | {
        status: AsyncStatus.NotStarted | AsyncStatus.Pending | AsyncStatus.Error;
      }
    | {
        data: Route;
        status: AsyncStatus.Success;
      };
  isDownloadingLabel: boolean;
  cancelOrder: {
    status: AsyncStatus;
  };
  changeOrderDriver: {
    status: AsyncStatus;
  };
  verifyPickUp: {
    status: AsyncStatus;
  };
  findAndVerifyPickUp: {
    status: AsyncStatus;
    verifiedOrders: string[];
  };
  holdOrder: {
    status: AsyncStatus;
  };
  releaseHeldOrder: {
    status: AsyncStatus;
  };
  addOrderComment: {
    status: AsyncStatus;
  };
}

const initialState: State = {
  orderList: {
    filters: {
      searchPhrase: '',
      statuses: [],
      createdAt: [startOfToday(), endOfToday()],
      companyId: null,
      region: null,
      productId: null,
    },
    data: [],
    status: AsyncStatus.NotStarted,
  },
  xDelivererRoute: {
    status: AsyncStatus.NotStarted,
  },
  isDownloadingLabel: false,
  cancelOrder: {
    status: AsyncStatus.NotStarted,
  },
  changeOrderDriver: {
    status: AsyncStatus.NotStarted,
  },
  verifyPickUp: {
    status: AsyncStatus.NotStarted,
  },
  findAndVerifyPickUp: {
    status: AsyncStatus.NotStarted,
    verifiedOrders: [],
  },
  holdOrder: {
    status: AsyncStatus.NotStarted,
  },
  releaseHeldOrder: {
    status: AsyncStatus.NotStarted,
  },
  addOrderComment: {
    status: AsyncStatus.NotStarted,
  },
};

const slice = createSlice({
  name: 'orderList',
  initialState,
  reducers: {
    fetchOrderList(state) {
      state.orderList.status = AsyncStatus.Pending;
    },
    fetchOrderListInBackground(_state) {},
    fetchOrderListSuccess(state, { payload }: PA<ActionTypes.FetchOrderListSuccess>) {
      state.orderList.status = AsyncStatus.Success;
      state.orderList.data = orderBy(
        payload.orders,
        ['stage.beganAt', 'createdAt'],
        ['asc', 'desc'],
      );
    },
    fetchOrderListError(state) {
      state.orderList.status = AsyncStatus.Error;
    },
    setFilters(state, { payload }: PA<ActionTypes.SetFilters>) {
      if (payload.searchPhrase !== undefined) {
        state.orderList.filters.searchPhrase = payload.searchPhrase;
      }
      if (payload.statuses !== undefined) {
        state.orderList.filters.statuses = payload.statuses;
      }
      if (payload.createdAt !== undefined) {
        state.orderList.filters.createdAt = payload.createdAt;
      }
      if (payload.companyId !== undefined) {
        state.orderList.filters.companyId = payload.companyId;
      }
      if (payload.region !== undefined) {
        state.orderList.filters.region = payload.region;
      }
      if (payload.productId !== undefined) {
        state.orderList.filters.productId = payload.productId;
      }
    },
    setSearchPhrase(state, { payload }: PA<ActionTypes.SetSearchPhrase>) {
      state.orderList.filters.searchPhrase = payload.searchPhrase;
    },
    fetchXDelivererRoute(state, { payload }: PA<ActionTypes.FetchXDelivererRoute>) {
      state.xDelivererRoute = {
        status: AsyncStatus.Pending,
      };
    },
    fetchXDelivererRouteSuccess(state, { payload }: PA<ActionTypes.FetchXDelivererRouteSuccess>) {
      state.xDelivererRoute = {
        status: AsyncStatus.Success,
        data: payload.route,
      };
    },
    fetchXDelivererRouteError(state) {
      state.xDelivererRoute = {
        status: AsyncStatus.Error,
      };
    },
    cancelOrder(state, { payload }: PA<ActionTypes.CancelOrder>) {
      state.cancelOrder = {
        status: AsyncStatus.Pending,
      };
    },
    cancelOrderSuccess(state) {
      state.cancelOrder = {
        status: AsyncStatus.Success,
      };
    },
    cancelOrderError(state) {
      state.cancelOrder = {
        status: AsyncStatus.Error,
      };
    },
    changeOrderDriver(state, { payload }: PA<ActionTypes.ChangeOrderDriver>) {
      state.changeOrderDriver = {
        status: AsyncStatus.Pending,
      };
    },
    changeOrderDriverSuccess(state) {
      state.changeOrderDriver = {
        status: AsyncStatus.Success,
      };
    },
    changeOrderDriverError(state) {
      state.changeOrderDriver = {
        status: AsyncStatus.Error,
      };
    },
    verifyOrderPickUp(state, { payload }: PA<ActionTypes.VerifyPickUp>) {
      state.verifyPickUp = {
        status: AsyncStatus.Pending,
      };
    },
    verifyOrderPickUpSuccess(state) {
      state.verifyPickUp = {
        status: AsyncStatus.Success,
      };
    },
    verifyOrderPickUpError(state) {
      state.verifyPickUp = {
        status: AsyncStatus.Error,
      };
    },
    findAndVerifyOrderPickup(state, { payload }: PA<ActionTypes.FindAndVerifyPickUp>) {
      state.findAndVerifyPickUp = {
        status: AsyncStatus.Pending,
        verifiedOrders: state.findAndVerifyPickUp.verifiedOrders,
      };
    },
    findAndVerifyOrderPickUpSuccess(
      state,
      { payload }: PA<ActionTypes.FindAndVerifyPickUpSuccess>,
    ) {
      state.findAndVerifyPickUp = {
        status: AsyncStatus.Success,
        verifiedOrders: [payload.orderNo, ...state.findAndVerifyPickUp.verifiedOrders],
      };
    },
    findAndVerifyOrderPickUpError(state) {
      state.findAndVerifyPickUp = {
        status: AsyncStatus.Error,
        verifiedOrders: state.findAndVerifyPickUp.verifiedOrders,
      };
    },
    holdOrder(state, { payload }: PA<ActionTypes.HoldOrder>) {
      state.holdOrder = {
        status: AsyncStatus.Pending,
      };
    },
    holdOrderSuccess(state) {
      state.holdOrder = {
        status: AsyncStatus.Success,
      };
    },
    holdOrderError(state) {
      state.holdOrder = {
        status: AsyncStatus.Error,
      };
    },
    releaseHeldOrder(state, { payload }: PA<ActionTypes.ReleaseHeldOrder>) {
      state.releaseHeldOrder = {
        status: AsyncStatus.Pending,
      };
    },
    releaseHeldOrderSuccess(state) {
      state.releaseHeldOrder = {
        status: AsyncStatus.Success,
      };
    },
    releaseHeldOrderError(state) {
      state.releaseHeldOrder = {
        status: AsyncStatus.Error,
      };
    },
    addOrderComment(state, { payload }: PA<ActionTypes.HoldOrder>) {
      state.addOrderComment = {
        status: AsyncStatus.Pending,
      };
    },
    addOrderCommentSuccess(state) {
      state.addOrderComment = {
        status: AsyncStatus.Success,
      };
    },
    addOrderCommentError(state) {
      state.addOrderComment = {
        status: AsyncStatus.Error,
      };
    },
    downloadOrderLabel(state, _action: PA<ActionTypes.DownloadOrderLabel>) {
      state.isDownloadingLabel = true;
    },
    downloadOrderLabelFinished(state) {
      state.isDownloadingLabel = false;
    },
    addXDelivererRouteHistoryRecord(
      state,
      { payload }: PA<ActionTypes.AddXDelivererRouteHistoryRecord>,
    ) {
      if (state.xDelivererRoute.status === AsyncStatus.Success) {
        state.xDelivererRoute.data.history = [
          ...state.xDelivererRoute.data.history,
          payload.location,
        ];
      }
    },
  },
});

export declare namespace ActionTypes {
  export interface FetchOrderListSuccess {
    orders: Order[];
  }

  export interface SetFilters extends Partial<Filters> {}

  export interface SetSearchPhrase {
    searchPhrase: string;
  }

  export interface DownloadOrderLabel {
    orderId: string;
  }

  export interface FetchXDelivererRoute {
    orderId: string;
  }

  export interface FetchXDelivererRouteSuccess {
    route: Route;
  }

  export interface CancelOrder {
    orderId: string;
    cancellationReason: string;
    comment?: string;
    forceCancel?: boolean;
    renewOrder?: boolean;
    createReturn?: boolean;
    payable?: boolean;
  }

  export interface ChangeOrderDriver {
    orderId: string;
    driverId: string;
    comment?: string;
  }

  export interface VerifyPickUp {
    orderId: string;
    pin: string;
  }

  export interface FindAndVerifyPickUp {
    orderNo: string;
    createdAt: Date;
    pin: string;
  }
  export interface FindAndVerifyPickUpSuccess {
    orderNo: string;
  }

  export interface HoldOrder {
    orderId: string;
    notes?: string;
    holdOrderTill?: Date;
  }

  export interface ReleaseHeldOrder {
    orderId: string;
  }

  export interface AddOrderComment {
    orderId: string;
    comment: string;
  }

  export interface AddXDelivererRouteHistoryRecord {
    location: Coordinates;
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectOrder: (orderId: string) =>
    createSelector(makeSelectDomain(), (state) => {
      return state.orderList.data?.find((order) => order.id === orderId);
    }),
  makeSelectOrderList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.orderList.data;
    }),
  makeSelectOrderListLoadingStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.orderList.status;
    }),
  makeSelectCancelOrderStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.cancelOrder.status;
    }),
  makeSelectChangeOrderDriverStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.changeOrderDriver.status;
    }),
  makeSelectVerifyPickUpStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.verifyPickUp.status;
    }),
  makeSelectFindAndVerifyPickUpStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.findAndVerifyPickUp.status;
    }),
  makeSelectFindAndVerifyPickUpVerifiedOrders: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.findAndVerifyPickUp.verifiedOrders;
    }),
  makeSelectHoldOrderStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.holdOrder.status;
    }),
  makeSelectReleaseHeldOrderStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.releaseHeldOrder.status;
    }),
  makeSelectAddOrderCommentStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.holdOrder.status;
    }),
  makeSelectOrderListFilters: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.orderList.filters;
    }),
  makeSelectXDelivererRoute: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.xDelivererRoute;
    }),
  makeSelectIsDownloadingLabel: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.isDownloadingLabel;
    }),
};
