import React from 'react';

import { styled } from '@mui/material/styles';
import { default as MuiModal } from '@mui/material/Modal';
import { useTranslation } from 'app/translations/useTranslation';
import { AddLocationForm } from './AddLocationForm';
import { Location } from '../../../../../model/Location';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit?: (location: Partial<Location>) => void;
  isAdmin?: boolean;
}

export function AddLocationModal(props: Props) {
  const { t } = useTranslation('settings');
  return (
    <>
      <Modal open={props.isOpen} onClose={props.onClose}>
        <ModalContentBox>
          <Box>
            <AddLocationForm
              onSubmit={props.onSubmit}
              onSubmitFinish={props.onClose}
              isAdmin={props.isAdmin}
            />
          </Box>
        </ModalContentBox>
      </Modal>
    </>
  );
}

const ModalContentBox = styled('div')`
  height: auto;
`;

const Modal = styled(MuiModal)`
  display: flex;
  align-items: flex-start;
  margin-top: 120px;
  justify-content: center;
`;

const Box = styled('div')(
  ({ theme }) => `
  width: 90vw;
  height: auto;
  padding: ${theme.spacing(6)};
  background: ${theme.colors.primary.background};
  border-radius: 5px;
`,
);
