import { filter } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { SelectFilter as Styled } from './styled';
import { useDrivers } from 'pages/DriverPage/state/useDrivers';
import FilterableLabeledSelect from '../../../../../components/Form/FilterableLabeledSelect';
import { useWarehouses } from '../../../../WarehousePage/state/useWarehouses';

interface Props {
  selectedWarehouseId: string | null;
  omitWarehouseId: string | null;
  onWarehouseChange: (warehouseId: string | null) => void;
  target: 'receivingWarehouse' | 'givingWarehouse';
}

const notSelectedValue = 'notSelectedValue';

export function WarehouseSelect(props: Props) {
  const { driverListing } = useDrivers();
  const { warehouseListing } = useWarehouses();
  const { t } = useTranslation('stock');
  return (
    <Styled.Box>
      <FilterableLabeledSelect
        label={t(`labels.${props.target}`)}
        onChange={(selectedValue) =>
          props.onWarehouseChange(
            selectedValue?.value === notSelectedValue || !selectedValue?.value
              ? null
              : selectedValue.value,
          )
        }
        // @ts-ignore
        listItems={(warehouseListing.list || [])
          .map((warehouse) => ({
            value: warehouse.id,
            display: `${warehouse.owner} - ${warehouse.region}`,
          }))
          .filter((warehouse) => !!warehouse)}
      />
    </Styled.Box>
  );
}
