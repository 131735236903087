import { filter } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { SelectFilter as Styled } from './styled';
import { useDrivers } from 'pages/DriverPage/state/useDrivers';
import FilterableLabeledSelect from '../../../../../components/Form/FilterableLabeledSelect';

interface Props {
  target: 'receivingType' | 'givingType';
  onEntityChange: (entity: string | null) => void;
}

const notSelectedValue = 'notSelectedValue';

export function EntitySelect(props: Props) {
  const { t } = useTranslation('stock');
  return (
    <Styled.Box>
      <FilterableLabeledSelect
        label={t(`labels.${props.target}`)}
        // @ts-ignore
        listItems={[
          { value: 'warehouse', display: 'Magazyn' },
          { value: 'driver', display: 'Kierowca' },
        ]}
        filterable={false}
        onChange={(selectedValue) => {
          props.onEntityChange(
            selectedValue?.value === notSelectedValue || !selectedValue?.value
              ? null
              : selectedValue.value,
          );
        }}
      />
    </Styled.Box>
  );
}
