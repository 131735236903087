import {
  ApiWarehouse,
  WarehouseAddStockParams as WarehouseAddStockApiParams,
} from 'api/WarehouseApi';
import { Warehouse, StockItemInfo, WarehouseAddStockParams } from '../Warehouse';
import { SimplifiedStock } from '../../StockPage/Stock';

class Service {
  public mapToWarehouse(warehouse: ApiWarehouse): Warehouse {
    return {
      id: warehouse.id,
      owner: warehouse.owner,
      companyId: warehouse.companyId,
      regionId: warehouse.regionId,
      region: warehouse.region,
      totalStockCount: this.mapStockCount(warehouse.stock),
      stock: warehouse.stock,
    };
  }

  public mapWarehouseAddStockToWarehouseAddStockApi(
    params: WarehouseAddStockParams,
  ): WarehouseAddStockApiParams[] {
    const arr = [];
    for (const productId of Object.keys(params.stock)) {
      if (params.stock[productId].stock) {
        arr.push({
          warehouseId: params.warehouseId,
          productId,
          amount: parseInt(params.stock[productId].stock, 10),
          serialNumber: params.stock[productId].batchNo,
        });
      }
    }
    return arr;
  }

  private mapStockCount(stock: { [key: string]: SimplifiedStock } | undefined): number {
    return stock ? Object.values(stock).reduce((cnt, x) => cnt + x['amount'], 0) : 0;
  }
}

export const ApiWarehouseMapper = new Service();
