import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useActivateUser() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectActivateUserStatus());

  return {
    activateUser: (params: Slice.ActionTypes.ActivateUser) => {
      dispatch(Slice.actions.activateUser(params));
    },
    isInProgress: status === AsyncStatus.Pending,
  };
}
