import React from 'react';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';

import { SimplifiedStock } from '../../../Stock';
import { StockTransferRowDetails } from './Row/components/StockTransferRowDetails/StockTransferRowDetails';
import { StockExchangeRowDetails } from './Row/components/StockExchangeRowDetails/StockExchangeRowDetails';

// Props type
type Props = {
  productId: string;
  amount: number;
  canTransferStock: boolean;
  showTransferStock: boolean;
  showPendingStockList: boolean;
  type: 'warehouse' | 'driver';
};

// Component
const ExpandedContent: React.FC<Props> = ({
  productId,
  amount,
  canTransferStock,
  showTransferStock,
  showPendingStockList,
  type,
}) => {
  return (
    <>
      {showTransferStock && (
        <StockTransferRowDetails
          productId={productId}
          stockAmount={amount}
          canTransferStock={canTransferStock}
          type={type}
        />
      )}
      {showPendingStockList && <StockExchangeRowDetails productId={productId} />}
    </>
  );
};

const Box = styled(MUIBox)({});

export default ExpandedContent;
