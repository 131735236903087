import React, { useCallback, useEffect, useState } from 'react';

import { FormProvider } from 'react-hook-form';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'app/translations/useTranslation';
import { PrimaryButton } from 'components/Button/Buttons';
import { Input } from 'components/ReactHookForm/Input/Input';
import { useChangePassword } from 'pages/SettingsPage/state/useChangePassword';
import { useAddLocationForm, AddLocationFormValues } from './hooks/useAddLocationForm';
import TrashIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, ListItem } from '@mui/material';
import { LocationListItem } from './LocationListItem';
import { Location } from '../../../../model/Location';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import { AddLocationModal } from './AddLocationModal/AddLocationModal';
import { UpdateLocationModal } from './UpdateLocationModal/UpdateLocationModal';
import { navigate, AppRoutes } from 'app/Navigation';
import { usePermissions } from '../../../../services/authorization/hooks/usePermissions';
interface Props {
  locations?: Partial<Location>[];
  onDelete?: (locationIndex: number) => void;
  onUpdate?: (locationId: string, payload: Partial<Location>) => void;
  admin?: boolean;
}

export function LocationList({ locations, onDelete, onUpdate, admin }: Props) {
  const { t } = useTranslation(['settings', 'validationErrors']);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const zipCodePermissions = usePermissions('zipCode');
  const [editingLocation, setEditingLocation] = useState<Partial<Location> | undefined>(undefined);

  const editLocation = useCallback((location) => {
    setIsOpen(true);
    setEditingLocation(location);
  }, []);

  const closeEditLocation = useCallback(() => {
    setIsOpen(false);
    setEditingLocation(undefined);
  }, []);
  return (
    <LocationListContainer container>
      {(locations ?? []).map((location, index) => {
        return (
          <Collapse>
            <LocationListMainContainer item container>
              <LocationListItemContainer
                item
                xs={onDelete ?? onUpdate ? 6 : 12}
                md={onDelete ?? onUpdate ? 9 : 12}
              >
                <LocationListItem location={location} admin={admin} />
              </LocationListItemContainer>
              {onDelete && (
                <LocationListActionContainer item xs={6} md={3}>
                  <PrimaryButton
                    loadingPosition="start"
                    onClick={() => {
                      onDelete(index);
                    }}
                    color="secondary"
                    startIcon={<TrashIcon />}
                    variant="text"
                  >
                    {t('locations.deleteLocationButton')}
                  </PrimaryButton>
                </LocationListActionContainer>
              )}
              {onUpdate && (
                <LocationListActionContainer item xs={6} md={3}>
                  <PrimaryButton
                    loadingPosition="start"
                    onClick={() => editLocation(location)}
                    color="primary"
                    startIcon={<EditIcon />}
                    variant="text"
                  >
                    {t('locations.editLocationButton')}
                  </PrimaryButton>
                  {zipCodePermissions.canAccessPage && location && (
                    <PrimaryButton
                      loadingPosition="start"
                      onClick={() =>
                        navigate(AppRoutes.zipCode.root(), {
                          street: location.address?.street,
                          city: location.address?.city,
                          zipCode: location.address?.zipCode,
                        })
                      }
                      color="primary"
                      variant="text"
                    >
                      {'Kody pocztowe'}
                    </PrimaryButton>
                  )}
                </LocationListActionContainer>
              )}
              {onUpdate && (
                <UpdateLocationModal
                  isOpen={isOpen}
                  onClose={() => closeEditLocation()}
                  location={editingLocation}
                  onSubmit={(payload) => onUpdate(location?.id ?? '', payload)}
                />
              )}
            </LocationListMainContainer>
          </Collapse>
        );
      })}
    </LocationListContainer>
  );
}

const LocationListContainer = styled(TransitionGroup)`
  margin-top: 15px;
`;

const LocationListMainContainer = styled(Grid)`
  align-items: center;
`;

const LocationListItemContainer = styled(Grid)``;

const LocationListActionContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(PrimaryButton)``;

const Form = styled('form')`
  max-width: 500px;
`;
