import { ApiRequestSaga } from './Request/ApiRequestSaga';
import { TimeRange } from '../pages/MultiplierPage/components/CreateMultiplier/CreateMultiplier';

interface ListMultipliersResponse {
  multipliers: Multiplier[];
}

interface ListMultipliersParams {
  dateFrom: Date;
  dateTo: Date;
}

export interface CreateMultiplierParams {
  dateFrom: Date;
  dateTo: Date;
  multiplier: number;
  description?: string;
  regions: string[];
  companyIds: string[];
  timeRanges?: TimeRange[];
}

export interface DeactivateMultiplierParams {
  multiplierId: string;
  deactivationReason?: String;
}

export interface Multiplier {
  id: string;
  createdAt: string;
  description?: string;
  regions: string[];
  companyIds: string[];
  multiplier: number;
  dateFrom: string;
  dateTo: string;
  deactivatedAt?: string;
  createdBy: {
    type: MultiplierCreatorType;
    id?: string;
    email?: string;
  };
  deactivatedBy?: {
    id: string;
    email: string;
  };
  deactivationReason?: string;
  timeRanges?: TimeRange[];
}

export enum MultiplierCreatorType {
  User = 'user',
}

export const MultiplierApiSaga = {
  listMultipliers: (params: ListMultipliersParams) => {
    return ApiRequestSaga.Get<ListMultipliersResponse>({
      endpoint: '/multiplier/list',
      query: {
        ...params,
        dateFrom: params.dateFrom.toISOString(),
        dateTo: params.dateTo.toISOString(),
      },
    });
  },
  createMultiplier(params: CreateMultiplierParams) {
    return ApiRequestSaga.Post<Multiplier>({
      endpoint: '/multiplier/create',
      data: {
        dateFrom: params.dateFrom.toISOString(),
        dateTo: params.dateTo.toISOString(),
        multiplier: params.multiplier,
        ...(params.description ? { description: params.description } : {}),
        regions: params.regions,
        companyIds: params.companyIds,
        ...(params.timeRanges ? { timeRanges: params.timeRanges } : {}),
      },
    });
  },
  deactivateMultiplier(params: DeactivateMultiplierParams) {
    return ApiRequestSaga.Post<void>({
      endpoint: '/multiplier/deactivate',
      data: {
        multiplierId: params.multiplierId,
        deactivationReason: params.deactivationReason,
      },
    });
  },
};
