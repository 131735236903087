import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import { Location } from '../../../../model/Location';

export const makeSelectCompanyLocations = (companyId: string) =>
  createSelector(selectState, (state) => state.locations[companyId]);

export const makeSelectCompanyLocationsAll = () =>
  createSelector(selectState, (state) => {
    // FIXME: Enable all locations fetch with proper key
    return state.locations['all'] ?? [];
  });
