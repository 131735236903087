import { ApiRequestSaga } from './Request/ApiRequestSaga';

export interface StockTransferBatchParams {
  givingDriverId: string;
  receivingDriverId: string;
  amount: number;
  productId: string;
}
export interface StockTransferBatchWarehouseToDriverParams {
  receivingDriverId: string;
  givingWarehouseId: string;
  amount: number;
  productId: string;
}
export interface StockTransferBatchWarehouseToWarehouseParams {
  receivingWarehouseId: string;
  givingWarehouseId: string;
  amount: number;
  productId: string;
}
export interface StockTransferBatchDriverToWarehouseParams {
  givingDriverId: string;
  receivingWarehouseId: string;
  amount: number;
  productId: string;
}
interface StockPendingListParams {
  bySrcDriver?: boolean;
  driverId: string;
}
interface GetStockParams {
  driverId: string;
}
export interface GetStockResponse {
  stock: SimplifiedStockApi;
  pendingStockExists?: boolean;
}

export interface SimplifiedStockApi {
  [propName: string]: SimplifiedStockItemApi;
}

export interface SimplifiedStockItemApi {
  amount: number;
  name: string;
  productId: string;
  productCode: string;
  companyId: string;
  companyImageUrl?: string;
  companyName?: string;
  imageUrl?: string;
  canTransferStock: boolean;
}

export enum SupportedStockExchangeStatus {
  InProgress = 'in_progress', // stock exchange package not ready
  Pending = 'pending', // ready to be claimed by driver
  Rejected = 'rejected', // rejected stock goes back to ambassador
  Confirmed = 'confirmed', // confirmed stock is received by driver
}

export interface StockExchangeApi {
  id: string;
  driverId: string;
  driverName: string;
  givingDriverId: string;
  givingDriverName: string;
  productId: string;
  productName: string;
  amount: number;
  status: SupportedStockExchangeStatus;
  createdAt: Date;
  rejectedAt?: Date;
  confirmedAt?: Date;
}
type GetPendingStockResponse = {
  stock: StockExchangeApi[];
};

export const StockApiSaga = {
  stockTransferBatch: (params: StockTransferBatchParams) => {
    return ApiRequestSaga.Post({
      endpoint: '/stock/transfer-batch-finish',
      data: {
        ...params,
      },
    });
  },
  stockTransferBatchWarehouseToDriver: (params: StockTransferBatchWarehouseToDriverParams) => {
    return ApiRequestSaga.Post({
      endpoint: '/stock/add',
      data: {
        amount: params.amount,
        warehouseId: params.givingWarehouseId,
        driverId: params.receivingDriverId,
        productId: params.productId,
      },
    });
  },
  stockTransferBatchWarehouseToWarehouse: (
    params: StockTransferBatchWarehouseToWarehouseParams,
  ) => {
    return ApiRequestSaga.Post({
      endpoint: '/stock/transfer-batch-wtw',
      data: {
        amount: params.amount,
        givingWarehouseId: params.givingWarehouseId,
        receivingWarehouseId: params.receivingWarehouseId,
        productId: params.productId,
      },
    });
  },
  stockTransferBatchDriverToWarehouse: (params: StockTransferBatchDriverToWarehouseParams) => {
    return ApiRequestSaga.Post({
      endpoint: '/stock/transfer-batch-dtw',
      data: {
        amount: params.amount,
        givingDriverId: params.givingDriverId,
        receivingWarehouseId: params.receivingWarehouseId,
        productId: params.productId,
      },
    });
  },
  getStock: (params: GetStockParams) => {
    return ApiRequestSaga.Get<GetStockResponse>({
      endpoint: '/driver/stock',
      query: {
        driverId: params.driverId,
      },
    });
  },
  stockPendingList: (params: StockPendingListParams) => {
    return ApiRequestSaga.Get<GetPendingStockResponse>({
      endpoint: '/stock/pending',
      query: {
        ...params,
      },
    });
  },
};
