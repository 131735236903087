import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useFilters() {
  const dispatch = useDispatch();
  const filters = useSelector(Slice.selectors.makeSelectStockTransferFilters());

  return {
    filters,
    changeFilters(filters: Slice.ActionTypes.SetFilters) {
      dispatch(Slice.actions.setFilters(filters));
    },
  };
}
