import { OperatorApi } from 'api/OperatorApi';
import { BadRequestError } from 'api/Request/RequestErrors';
import { translate } from 'app/translations/useTranslation';
import { call, takeLatest, takeLeading } from 'redux-saga/effects';
import { addSuccessToast, addErrorToast } from 'services/toast';
import { put } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { ApiOperatorACL } from './ApiOperatorACL';
import * as Slice from './slice';

function* fetchUserList() {
  try {
    const response = yield* OperatorApi.Saga.listOperators();
    const users = response.operators.map((operator) => ApiOperatorACL.mapOperatorToUser(operator));
    yield put(Slice.actions.fetchUserListSuccess({ users }));
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.fetchUserListError());
  }
}

function* createUser({ payload }: PA<Slice.ActionTypes.CreateUser>) {
  const createData = ApiOperatorACL.mapCreateUserToCreateOperator(payload.data);
  try {
    yield* OperatorApi.Saga.createOperator(createData);
    yield call(addSuccessToast, {
      message: translate('user', 'notifications.userCreated'),
    });
  } catch (e) {
    if (
      e instanceof BadRequestError &&
      e.response.errorCode === OperatorApi.ErrorCodes.EmailOccupied
    ) {
      yield call(addErrorToast, {
        message: translate('user', 'notifications.emailOccupied'),
      });
    } else {
      yield call(addErrorToast, {
        message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
      });
      console.error(e);
    }
  }
  yield put(Slice.actions.createUserFinish());
}

function* updateUser({ payload }: PA<Slice.ActionTypes.UpdateUser>) {
  const updateData = ApiOperatorACL.mapUpdateUserToUpdateOperator(payload.data);
  try {
    yield* OperatorApi.Saga.updateOperator(updateData);
    yield call(addSuccessToast, {
      message: translate('user', 'notifications.userUpdated'),
    });
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.updateUserFinish());
  yield put(Slice.actions.fetchUserList());
}

function* changeOperatorPassword({ payload }: PA<Slice.ActionTypes.ChangeOperatorPassword>) {
  try {
    yield* OperatorApi.Saga.changeOperatorPassword(payload);
    yield call(addSuccessToast, {
      message: translate('user', 'notifications.userPasswordChanged'),
    });
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.changeOperatorPasswordFinish());
  yield put(Slice.actions.fetchUserList());
}

function* deactivateUser({ payload }: PA<Slice.ActionTypes.DeactivateUser>) {
  try {
    yield* OperatorApi.Saga.deactivateUser(payload);
    yield call(addSuccessToast, {
      message: translate('user', 'notifications.userDeactivated'),
    });
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.deactivateUserFinish());
  yield put(Slice.actions.fetchUserList());
}

function* activateUser({ payload }: PA<Slice.ActionTypes.ActivateUser>) {
  try {
    yield* OperatorApi.Saga.activateUser(payload);
    yield call(addSuccessToast, {
      message: translate('user', 'notifications.userActivated'),
    });
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.activateUserFinish());
  yield put(Slice.actions.fetchUserList());
}

export function* saga() {
  yield takeLatest(Slice.actions.fetchUserList, fetchUserList);
  yield takeLatest(Slice.actions.createUserFinish, fetchUserList);
  yield takeLeading(Slice.actions.createUser, createUser);
  yield takeLeading(Slice.actions.updateUser, updateUser);
  yield takeLeading(Slice.actions.changeOperatorPassword, changeOperatorPassword);
  yield takeLeading(Slice.actions.deactivateUser, deactivateUser);
  yield takeLeading(Slice.actions.activateUser, activateUser);
}
