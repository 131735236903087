const rootUrl = '/zipCodes';

export const LinkFactory = {
  root: (locationId?: string) => {
    let query;
    if (locationId) {
      query = `?locationId=${locationId}`;
    }
    return query ? rootUrl + query : rootUrl;
  },
};
