import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useTranslation } from '../../app/translations/useTranslation';
import { useLanguageManager } from '../../app/translations/useLanguageManager';
import * as Styled from '../ReactHookForm/Select/styled';
import { useFormContext, Controller } from 'react-hook-form';

interface Props {
  label: string;
  onChange: (newValue: Date | null) => void;
  value: Date | null;
  minDateTime?: Date | undefined;
  fieldState?: {
    error?: any;
    invalid: boolean;
  };
  name: string;
}

function ParsedErrorMessage(props: { inputName: string; message: string }) {
  return (
    <Styled.ErrorBox data-cy={`input_error-${props.inputName}`}>
      {props.message.replace(`"${props.inputName}"`, 'Value')}
    </Styled.ErrorBox>
  );
}

export function DateSelect(props: Props) {
  const { t } = useTranslation('common');
  const { dateLocale } = useLanguageManager();
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={props.name}
      rules={{ required: true }}
      render={({ field }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateLocale}>
            <DatePicker
              label={props.label}
              value={field.value}
              inputRef={field.ref}
              disableFuture={true}
              onChange={(date) => {
                field.onChange(date);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    name={props.name}
                    value={props.value}
                    error={!!props.fieldState?.error}
                    style={{ width: '100%' }}
                    helperText={
                      !!props.fieldState?.error ? (
                        <ParsedErrorMessage
                          inputName={props.name}
                          message={props.fieldState?.error.message}
                        />
                      ) : undefined
                    }
                    {...params}
                  />
                );
              }}
              todayText={t('dateTimePicker.todayText')}
              cancelText={t('dateTimePicker.cancelText')}
              okText={t('dateTimePicker.okText')}
              clearText={t('dateTimePicker.clearText')}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}
