import React from 'react';
import { useTranslation } from 'app/translations/useTranslation';
import { SecondaryButton } from 'components/Button/Buttons';
import { useState } from 'react';
import { ActivateUserModal } from './ActivateUserModal';

interface Props {
  operatorId: string;
  onClose?: VoidFunction;
}

export function ShowActivateUserModalAction(props: Props) {
  const { t } = useTranslation('user');
  const [isModalOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <SecondaryButton onClick={() => setModalIsOpen(true)}>
        {t('actions.showActivateUserModal')}
      </SecondaryButton>
      <ActivateUserModal
        isOpen={isModalOpen}
        onClose={() => {
          setModalIsOpen(false);
          props.onClose?.();
        }}
        operatorId={props.operatorId}
      />
    </>
  );
}
