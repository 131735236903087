import { DayWeekSettings, WeekDay } from '../components/Location/WeeklyCalendar/WeeklyCalendar';
import { LocationWorkingSettings } from '../../../model/Location';
import { isNil, map, mapValues, omitBy } from 'lodash';

class Service {
  public mapWorkingSettingsToApi(workingSettings: {
    [day in WeekDay]: DayWeekSettings;
  }): LocationWorkingSettings | undefined {
    if (map(workingSettings, 'isOpen').includes(true)) {
      return {
        workingDays: mapValues(workingSettings, (settings, dayWeek) => {
          return settings.isOpen === true;
        }),
        nonStandardWorkingHours: omitBy(
          mapValues(workingSettings, (settings, dayWeek) => {
            if (
              !!settings?.from?.fullDate &&
              !!settings?.to?.fullDate &&
              settings.from.fullDate < settings.to.fullDate &&
              //@ts-ignore
              workingSettings[dayWeek]?.isOpen === true
            ) {
              return {
                from: {
                  hour: settings?.from?.hour,
                  minute: settings?.from?.minute,
                },
                to: {
                  hour: settings?.to?.hour,
                  minute: settings?.to?.minute,
                },
              };
            }
            return undefined;
          }),
          isNil,
        ),
      };
    }
    return undefined;
  }
  public mapWorkingSettingsToLocal(workingSettings?: LocationWorkingSettings):
    | {
        [day in WeekDay]: DayWeekSettings;
      }
    | undefined {
    if (workingSettings && workingSettings.workingDays) {
      return mapValues(
        workingSettings.workingDays,
        (isOpen: boolean | undefined, dayWeek): DayWeekSettings => {
          const hours =
            //@ts-ignore
            workingSettings.nonStandardWorkingHours?.[dayWeek] ??
            workingSettings.defaultWorkingHours;
          const fullDateFrom = new Date();
          fullDateFrom.setHours(hours?.from?.hour, hours?.from?.minute);
          const fullDateTo = new Date();
          fullDateTo.setHours(hours?.to?.hour, hours?.to?.minute);
          return {
            isOpen: isOpen,
            from: {
              hour: hours?.from?.hour,
              minute: hours?.from?.minute,
              fullDate: fullDateFrom,
            },
            to: {
              hour: hours?.to?.hour,
              minute: hours?.to?.minute,
              fullDate: fullDateTo,
            },
          };
        },
      );
    }
    return undefined;
  }
}

export const ApiLocationMapper = new Service();
