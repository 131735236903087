export type Permissions = {
  order: {
    canAccessPage: boolean;
    canWrite: boolean;
    canView: {
      cancellation: boolean;
      pinCode: boolean;
      sensitiveData: boolean;
      holdOrder: boolean;
      orderEstimations: boolean;
      orderRoute: boolean;
      comments: boolean;
      changeDriver: boolean;
      driverInfo: boolean;
      driverPayout: boolean;
    };
  };
  company: {
    canAccessPage: boolean;
    canWrite: boolean;
  };
  report: {
    canAccessPage: boolean;
    reports: {
      canAccessDriverActivityReport: boolean;
      canAccessFullReport: boolean;
      canAccessOffersReport: boolean;
      canAccessDriversStockReport: boolean;
      canAccessCorrectionsReport: boolean;
      canAccessDriversFeedbackReport: boolean;
      canAccessAuditStockMovementReport: boolean;
      canAccessStockExchangeReport: boolean;
    };
  };
  user: {
    canAccessPage: boolean;
  };
  driver: {
    canAccessPage: boolean;
    notifications: {
      canAccessSendPushNotification: boolean;
    };
  };
  warehouse: {
    canAccessPage: boolean;
  };
  stock: {
    canAccessPage: boolean;
  };
  multiplier: {
    canAccessPage: boolean;
  };
  correction: {
    canAccessPage: boolean;
  };
  wallet: {
    canAccessPage: boolean;
  };
  zipCode: {
    canAccessPage: boolean;
  };
};

export const NotAuthorizedPermissions: Permissions = {
  order: {
    canAccessPage: false,
    canWrite: false,
    canView: {
      cancellation: false,
      pinCode: false,
      sensitiveData: false,
      holdOrder: false,
      orderEstimations: false,
      orderRoute: false,
      comments: false,
      changeDriver: false,
      driverInfo: false,
      driverPayout: false,
    },
  },
  company: {
    canAccessPage: false,
    canWrite: false,
  },
  report: {
    canAccessPage: false,
    reports: {
      canAccessDriverActivityReport: false,
      canAccessFullReport: false,
      canAccessOffersReport: false,
      canAccessDriversStockReport: false,
      canAccessCorrectionsReport: false,
      canAccessDriversFeedbackReport: false,
      canAccessAuditStockMovementReport: false,
      canAccessStockExchangeReport: false,
    },
  },
  user: {
    canAccessPage: false,
  },
  driver: {
    canAccessPage: false,
    notifications: {
      canAccessSendPushNotification: false,
    },
  },
  warehouse: {
    canAccessPage: false,
  },
  stock: {
    canAccessPage: false,
  },
  multiplier: {
    canAccessPage: false,
  },
  correction: {
    canAccessPage: false,
  },
  wallet: {
    canAccessPage: false,
  },
  zipCode: {
    canAccessPage: false,
  },
};
