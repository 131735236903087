import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'app/translations/useTranslation';
import { Checkbox } from 'components/ReactHookForm/Checkbox/Checkbox';
import { getErrorForField } from 'components/ReactHookForm/Checkbox/CheckboxGroup';
import { UserPermission } from 'pages/UserPage/User';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Region } from 'model/Region';
import * as Styled from '../styled';
import { Company } from 'model/Company';
import { Location } from '../../../../../../../model/Location';

const wildCardRegionValue = 'all';

interface Props {
  companies: Company[];
  regions: Region[];
  locations?: Location[];
  errors: FieldErrors;
  selectedRegions?: { [key: string]: string };
  selectedCompanies?: { [key: string]: string };
}

export function PermissionManager(props: Props) {
  const { t } = useTranslation('user');
  const { companies, regions, errors, locations } = props;

  const wildcardRegionSelected = Object.values(props.selectedRegions ?? {}).includes(
    wildCardRegionValue,
  );

  const canViewPermissions: string[] = [
    'sensitiveData',
    'pinCode',
    'cancellation',
    'holdOrder',
    'orderEstimations',
    'orderRoute',
    'comments',
    'changeDriver',
    'driverInfo',
    'driverPayout',
  ];

  return (
    <Styled.PermissionBox>
      <Styled.CheckboxGroup
        label={t('fields.assignedCompanies')}
        error={getErrorForField(errors, 'assignedCompanyIds')}
      >
        {companies.map((company, index) => {
          return (
            <Checkbox
              label={company.name}
              name={`assignedCompanyIds.${company.id}`}
              key={company.name}
              value={company.id}
            />
          );
        })}
      </Styled.CheckboxGroup>
      <Styled.CheckboxGroup
        label={t('fields.assignedRegions')}
        error={getErrorForField(errors, 'assignedRegionNames')}
      >
        {[{ name: t('fields.allRegions'), value: wildCardRegionValue }, ...regions].map(
          (region: { name: string; value?: string }, index) => {
            return (
              <Checkbox
                label={region.name}
                name={`assignedRegionNames.${region.value ?? region.name}`}
                key={region.name}
                value={region.value ?? region.name}
                disabled={wildcardRegionSelected && region.value !== wildCardRegionValue}
              />
            );
          },
        )}
      </Styled.CheckboxGroup>
      <Styled.CheckboxGroup
        label={t('fields.userPermissions')}
        error={getErrorForField(errors, 'permissions')}
      >
        {Object.values(UserPermission).map((permission, index) => {
          return (
            <Checkbox
              label={t(`fields.permissions.${permission.replace(':', '_')}`)}
              name={`permissions.${permission.replace('.', '_')}`}
              key={permission}
              value={permission}
              shouldUnregister
            />
          );
        })}
      </Styled.CheckboxGroup>
      <Styled.CheckboxGroup
        label={t('fields.canViewPermissions')}
        error={getErrorForField(errors, 'canView')}
      >
        {canViewPermissions.map((permission, index) => {
          return (
            <Checkbox
              label={t(`fields.canView.${permission}`)}
              name={`canView.${permission}`}
              key={index}
            />
          );
        })}
      </Styled.CheckboxGroup>
      <Styled.CheckboxGroup
        label={t('fields.assignedLocations')}
        error={getErrorForField(errors, 'assignedLocations')}
      >
        {(locations ?? []).map((location, index) => {
          return (
            <Checkbox
              label={`${location.name} (${location.address?.formatted})`}
              name={`assignedLocations.${location.id}`}
              key={location.id}
              value={location.id}
            />
          );
        })}
      </Styled.CheckboxGroup>
    </Styled.PermissionBox>
  );
}
