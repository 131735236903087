import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { useCompanies } from 'services/companies/state/useCompanies';
import { SelectFilter as Styled } from './styled';
import { CompanyStatus } from '../../../../../../model/Company';

interface Props {
  selectedCompanyId: string | null;
  onCompanyChange: (companyId: string | null) => void;
}

const notSelectedValue = 'notSelectedValue';

export function CompanySelect(props: Props) {
  const { companies } = useCompanies();
  const { t } = useTranslation('order');

  return (
    <Styled.Box>
      <LabeledSelect
        id={'select-company'}
        onChange={MuiHandlers.Select.onChange((selectedValue) => {
          props.onCompanyChange(selectedValue === notSelectedValue ? null : selectedValue);
        })}
        label={t('labels.company')}
        value={props.selectedCompanyId ?? notSelectedValue}
        size="small"
      >
        <MenuItem value={notSelectedValue} key={notSelectedValue}>
          {'---'}
        </MenuItem>
        {companies
          .filter((company) => company.active !== false && company.status === CompanyStatus.Active)
          .map((company) => (
            <MenuItem value={company.id} key={company.id} data-label={company.name}>
              {company.name}
            </MenuItem>
          ))}
      </LabeledSelect>
    </Styled.Box>
  );
}
