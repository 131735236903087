import * as React from 'react';
import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import DateTimePicker from '@mui/lab/DateTimePicker';
import { useTranslation } from '../../app/translations/useTranslation';
import { useLanguageManager } from '../../app/translations/useLanguageManager';
import * as Styled from '../ReactHookForm/Select/styled';

interface Props {
  label: string;
  onChange: (newValue: Date | null) => void;
  value: Date | null;
  minDateTime?: Date | undefined;
  fieldState?: {
    error?: any;
    invalid: boolean;
  };
  name: string;
  disabled?: boolean;
}

function ParsedErrorMessage(props: { inputName: string; message: string }) {
  return (
    <Styled.ErrorBox data-cy={`input_error-${props.inputName}`}>
      {props.message.replace(`"${props.inputName}"`, 'Value')}
    </Styled.ErrorBox>
  );
}

export function TimeSelect(props: Props) {
  const { t } = useTranslation('common');
  const { dateLocale } = useLanguageManager();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
      <TimePicker
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled === true ? true : false}
        //@ts-ignore
        renderInput={(params) => (
          <TextField
            name={props.name}
            value={props.value}
            error={!!props.fieldState?.error}
            helperText={
              !!props.fieldState?.error ? (
                <ParsedErrorMessage
                  inputName={props.name}
                  message={props.fieldState?.error.message}
                />
              ) : undefined
            }
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
}
