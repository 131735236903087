import { useTranslation } from 'app/translations/useTranslation';
import { TransferStockForm } from './forms/TransferStockForm';
import * as Styled from './styled';

interface Props {
  productId: string;
  stockAmount: number;
  canTransferStock: boolean;
  type?: 'warehouse' | 'driver';
}

export function StockTransferRowDetails(props: Props) {
  const { t } = useTranslation('stock');
  if (!props.canTransferStock) {
    return <Styled.Box>{t('labels.cannotTransferStock')}</Styled.Box>;
  }
  return (
    <Styled.Box>
      <TransferStockForm productId={props.productId} stockAmount={props.stockAmount} />
    </Styled.Box>
  );
}
