import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AsyncStatus, PA } from 'utlis/State';
import { endOfToday, startOfToday, startOfYesterday } from 'date-fns';
import { Multiplier } from '../../MultiplierPage/model/Multiplier';

export interface State {
  zipCodes: {
    data: any[];
    dataOutOfBounds: any[];
    centerPoint: { lat: number; lng: number } | null;
    status: AsyncStatus;
  };
}

const initialState: State = {
  zipCodes: {
    data: [],
    dataOutOfBounds: [],
    centerPoint: null,
    status: AsyncStatus.NotStarted,
  },
};

const slice = createSlice({
  name: 'zipCode',
  initialState,
  reducers: {
    getZipCodes(state, { payload }: PA<ActionTypes.GetZipCodes>) {
      state.zipCodes.status = AsyncStatus.Pending;
    },
    getZipCodesSuccess(state, { payload }: PA<ActionTypes.GetZipCodesSuccess>) {
      state.zipCodes.status = AsyncStatus.Success;
      state.zipCodes.data = payload.zipCodes;
      state.zipCodes.dataOutOfBounds = payload.zipCodesOutOfBounds;
      state.zipCodes.centerPoint = payload.centerPoint;
    },
    getZipCodesError(state) {
      state.zipCodes.status = AsyncStatus.Error;
    },
  },
});

export declare namespace ActionTypes {
  export interface GetZipCodes {
    radius: number;
    city: string;
    street: string;
    zipCode: string;
  }
  export interface GetZipCodesSuccess {
    zipCodes: any[];
    zipCodesOutOfBounds: any[];
    centerPoint: { lat: number; lng: number } | null;
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectZipCodesList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.zipCodes.data;
    }),
  makeSelectZipCodesOutOfBoundsList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.zipCodes.dataOutOfBounds;
    }),
  makeSelectCenterPoint: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.zipCodes.centerPoint;
    }),
  makeSelectZipCodesListStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.zipCodes.status;
    }),
};
