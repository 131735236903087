import { useState } from 'react';
import { ActiveStatusSelectProps, ActiveStatusSelect } from './ActiveStatusSelect';

export function useActiveStatusSelect() {
  const [selectedActiveStatus, setSelectedActiveStatus] = useState<string>('active');

  const activeStatusSelectProps: ActiveStatusSelectProps = {
    selectedActiveStatus,
    onActiveStatusChange: setSelectedActiveStatus,
  };

  return {
    selectedActiveStatus,
    activeStatusSelectProps,
    ActiveStatusSelect: ActiveStatusSelect,
  };
}
