import { find } from 'lodash';
import { useTranslation } from 'app/translations/useTranslation';
import { Input } from 'components/ReactHookForm/Input/Input';
import { useCreateUser } from 'pages/UserPage/state/useCreateUser';
import { UserPermission } from 'pages/UserPage/User';
import React, { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useCompanies } from 'services/companies/state/useCompanies';
import { useRegions } from 'services/regions/state/useRegions';
import { PermissionManager } from './components/PermissionManager';
import { useCreateForm, CreateFormValues } from './hooks/useCreateForm';
import * as Styled from './styled';
import { Select } from 'components/ReactHookForm/Select/Select';
import { AccountType } from 'api/OperatorApi';
import { useLocations } from '../../../../../../services/regions/state/hooks/useLocations';

interface Props {}

export function UserCreator(props: Props) {
  const { t } = useTranslation('user');
  const { companies } = useCompanies();
  const { regions } = useRegions();

  const { createUser, isInProgress: isInProgressCreateUser } = useCreateUser();

  const form = useCreateForm();

  const companyIds = Object.values(form.watch('assignedCompanyIds') ?? {}).filter(
    (companyId) => !!companyId,
  );
  const { fetchAllLocations, locationsAll } = useLocations(companies?.[0]?.id);
  useEffect(() => {
    fetchAllLocations();
  }, []);

  const filteredLocations = useMemo(() => {
    return locationsAll.filter((location) => {
      return companyIds.includes(location.companyId);
    });
  }, [companyIds, locationsAll]);
  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.Form
          onSubmit={form.handleSubmit((formValues: CreateFormValues) => {
            createUser({
              ...formValues,
              permissions: Object.values(formValues.permissions).filter(
                (v) => !!v,
              ) as UserPermission[],
              assignedCompanyIds: Object.values(formValues.assignedCompanyIds).filter(
                (value) => !!value,
              ) as string[],
              assignedRegionNames: Object.values(formValues.assignedRegionNames).includes('all')
                ? ['*']
                : (Object.values(formValues.assignedRegionNames).filter(
                    (value) => !!value,
                  ) as string[]),
              assignedLocations: Object.values(formValues.assignedLocations ?? {}).filter(
                (value) => !!value,
              ),
              canView: {
                pinCode: !!formValues.canView.pinCode,
                cancellation: !!formValues.canView.cancellation,
                sensitiveData: !!formValues.canView.sensitiveData,
                holdOrder: !!formValues.canView.holdOrder,
                orderEstimations: !!formValues.canView.orderEstimations,
                orderRoute: !!formValues.canView.orderRoute,
                comments: !!formValues.canView.comments,
                changeDriver: !!formValues.canView.changeDriver,
                driverInfo: !!formValues.canView.driverInfo,
                driverPayout: !!formValues.canView.driverPayout,
              },
            });
          })}
        >
          <Styled.SaveButton
            type={'submit'}
            dataCy={'button-create_user'}
            isInProgress={isInProgressCreateUser}
          >
            {t('buttons.create')}
          </Styled.SaveButton>
          <Styled.DetailsBox>
            <Select
              name={'accountType'}
              label={t('labels.accountType')}
              values={Object.values(AccountType)
                .filter((accountType) => accountType !== AccountType.Deactivated)
                .map((accountType) => ({
                  value: accountType,
                  display: t(`labels.accountTypeValues.${accountType}`),
                }))}
              defaultValue={AccountType.Api}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <Input name={'email'} label={t('fields.email')} dataCy={'input-email'} />
            <Input
              name={'password'}
              label={t('fields.password')}
              type={'password'}
              dataCy={'input-password'}
            />
            <Input
              name={'passwordConfirmation'}
              label={t('fields.passwordConfirmation')}
              type={'password'}
              dataCy={'input-passwordConfirmation'}
            />
          </Styled.DetailsBox>
          <PermissionManager
            companies={companies}
            regions={regions}
            locations={filteredLocations}
            errors={form.formState.errors}
            selectedRegions={form.watch('assignedRegionNames')}
          />
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
