import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { ListBody } from './components/ListBody';
import { ListHeaders } from './components/ListHeaders';
import { SimplifiedStock } from '../../Stock';
import useMUIDataTableDefaultOptions from '../../../../utlis/MUIDataTables/useMUIDataTableDefaultOptions';
import React, { useMemo } from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { OrderRowDetails } from '../../../Orders/OrderPage/components/OrderList/components/Row/components/OrderRowDetails/OrderRowDetails';
import { StockTransferRowDetails } from './components/Row/components/StockTransferRowDetails/StockTransferRowDetails';
import { StockExchangeRowDetails } from './components/Row/components/StockExchangeRowDetails/StockExchangeRowDetails';
import Collapse from '@mui/material/Collapse';
import ExpandedContent from './components/ExpandedContent';
import { useTranslation } from 'react-i18next';
import { StockItemInfo, Warehouse } from '../../../WarehousePage/Warehouse';
import { values, mapKeys } from 'lodash';

interface Props {
  stock?: {
    [key: string]: SimplifiedStock;
  };
  reload: VoidFunction;
  showTransferStock?: boolean;
  label: string;
}

export function WarehouseStockList(props: Props) {
  const { t } = useTranslation('stock');

  /*const stock: {
    productId: string;
    amount: number;
  }[] = useMemo((): {
    productId: string;
    amount: number;
  }[] => {
    return props.stock
      ? Object.keys(props.stock).map((key) => ({
          productId: key,
          amount: props.stock?.[key]?.amount ?? 0,
        }))
      : [];
  }, [props.stock]);*/
  const stock = useMemo(
    () => values(props.stock).map((stockItem) => ({ stockItem })),
    [props.stock],
  );

  const defaultOptions = useMUIDataTableDefaultOptions();
  const options = useMemo<MUIDataTableOptions>(
    () => ({
      ...defaultOptions,
      expandableRows: true,
      expandableRowsOnClick: true,
      expandableRowsHeader: false,
      renderExpandableRow: (rowData, rowMeta) => (
        <TableRow>
          <TableCell colSpan={rowData.length + 1}>
            <ExpandedContent
              productId={stock[rowMeta.dataIndex].stockItem.productId}
              amount={stock[rowMeta.dataIndex].stockItem.amount}
              type={'warehouse'}
              canTransferStock={true}
              showPendingStockList={false}
              showTransferStock={props.showTransferStock ?? false}
            />
          </TableCell>
        </TableRow>
      ),
      search: false,
      searchOpen: false,
      searchAlwaysOpen: false,
      filter: false,
      viewColumns: false,
    }),
    [defaultOptions, stock, props.showTransferStock],
  );

  const columns = useMemo(
    () => [
      { name: 'stockItem.name', label: t('list.headers.productName') },
      { name: 'stockItem.amount', label: t('list.headers.amount') },
    ],
    [],
  );

  // <TableHeaderCell>{t('list.headers.productName')}</TableHeaderCell>
  //       <TableHeaderCell>{t('list.headers.amount')}</TableHeaderCell>

  return (
    <MUIDataTable title={'' /*props.label*/} data={stock} columns={columns} options={options} />
  );
}
