import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';

export type PickUpVerificationFormValues = {
  pin: string;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object().shape({
      pin: yup.string().required(t('required')),
    });
  }, [t]);
}

export const usePickUpVerificationFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    form.reset();
  }, [form]);
};

export function usePickUpVerificationForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<PickUpVerificationFormValues>([
      simpleResolver<PickUpVerificationFormValues>(),
      yupResolver(validationSchema),
    ]),
    [validationSchema],
  );
  return useForm<PickUpVerificationFormValues>({
    resolver,
    mode: 'onSubmit',
  });
}
