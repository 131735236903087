import { ApiRequestSaga } from './Request/ApiRequestSaga';
import {
  ActivateUserParams,
  ChangeOperatorPasswordParams,
  DeactivateUserParams,
} from '../pages/UserPage/User';

export const OmniValue = '*';

export interface Operator {
  id: string;
  email: string;
  scope: OperatorScope[];
  accountType: AccountType;
  companyIds: (string | typeof OmniValue)[];
  regions: (string | typeof OmniValue)[];
  locationIds?: string[];
  createdAt: string;
  canView?: OperatorCanView;
}

export interface OperatorCanView {
  pinCode?: boolean;
  cancellation?: boolean;
  sensitiveData?: boolean;
  holdOrder?: boolean;
  orderEstimations?: boolean;
  orderRoute?: boolean;
  comments?: boolean;
  changeDriver?: boolean;
  driverInfo?: boolean;
  driverPayout?: boolean;
}

export interface ListOperatorsResponse {
  operators: Operator[];
}

export enum AccountType {
  Api = 'api', // api user for integrations
  ApiUser = 'api_user', // additional api user for client
  XDEmployee = 'xd_employee', // internal XD employee
  XDCoordinator = 'xd_coordinator', // XD coordinator
  Other = 'other',
  Deactivated = 'deactivated',
}

export enum OperatorScope {
  OmniValue = '*',
  Reports = 'reports',
  OrdersRead = 'orders:read',
  OrdersWrite = 'orders:write',
  Orders = 'orders',
  Companies = 'companies',
  SuperAdmin = 'superAdmin',
  Operators = 'operators',
  Drivers = 'drivers',
  ReportOffers = 'reports:offers',
  ReportFull = 'reports:full',
  ReportDriverActivity = 'reports:driverActivity',
  ReportDriversStock = 'reports:driversStock',
  ReportCorrections = 'reports:corrections',
  ReportDriversFeedback = 'reports:driversFeedback',
  ReportAuditStockMovement = 'reports:auditStockMovement',
  ReportStockExchange = 'reports:stockExchange',
  Warehouse = 'warehouse',
  Stock = 'stock',
  Notifications = 'notifications',
  Corrections = 'corrections',
  CorrectionsWrite = 'corrections:write',
  Correctionsread = 'corrections:read',
  Wallet = 'wallet',
  ZipCodes = 'zipCodes',
}

export interface CreateOperatorParams {
  email: string;
  password: string;
  companyIds: string[];
  regions: string[];
  scope: OperatorScope[];
  accountType: AccountType;
  locationIds?: string[];
  canView: {
    pinCode: boolean;
    cancellation: boolean;
    sensitiveData: boolean;
    holdOrder: boolean;
    orderEstimations: boolean;
    orderRoute: boolean;
    comments: boolean;
    changeDriver: boolean;
    driverInfo: boolean;
    driverPayout: boolean;
  };
}

export interface UpdateOperatorParams {
  operatorId: string;
  companyIds: string[];
  locationIds?: string[];
  regions: string[];
  scope: OperatorScope[];
  accountType: AccountType;
  canView: {
    pinCode: boolean;
    cancellation: boolean;
    sensitiveData: boolean;
    holdOrder: boolean;
    orderEstimations: boolean;
    orderRoute: boolean;
    comments: boolean;
    changeDriver: boolean;
    driverInfo: boolean;
    driverPayout: boolean;
  };
}

export namespace OperatorApi {
  export enum ErrorCodes {
    EmailOccupied = 'user_email_already_occupied',
  }

  export const Saga = {
    listOperators: () => {
      return ApiRequestSaga.Get<ListOperatorsResponse>({
        endpoint: '/operator/list',
      });
    },
    createOperator: (params: CreateOperatorParams) => {
      return ApiRequestSaga.Post({
        endpoint: '/operator/create',
        data: {
          email: params.email,
          password: params.password,
          companyIds: params.companyIds,
          regions: params.regions,
          scope: params.scope,
          accountType: params.accountType,
          canView: params.canView,
          ...(params.locationIds?.length && { locationIds: params.locationIds }),
        },
      });
    },
    updateOperator: (params: UpdateOperatorParams) => {
      return ApiRequestSaga.Post({
        endpoint: '/operator/update',
        data: {
          operatorId: params.operatorId,
          companyIds: params.companyIds,
          regions: params.regions,
          scope: params.scope,
          accountType: params.accountType,
          canView: params.canView,
          locationIds: params.locationIds,
        },
      });
    },
    changeOperatorPassword: (params: ChangeOperatorPasswordParams) => {
      return ApiRequestSaga.Post({
        endpoint: '/operator/change-password',
        data: {
          operatorId: params.operatorId,
          newPassword: params.password,
        },
      });
    },
    deactivateUser: (params: DeactivateUserParams) => {
      return ApiRequestSaga.Post({
        endpoint: '/operator/deactivate',
        data: {
          operatorId: params.operatorId,
        },
      });
    },
    activateUser: (params: ActivateUserParams) => {
      return ApiRequestSaga.Post({
        endpoint: '/operator/activate',
        data: {
          operatorId: params.operatorId,
        },
      });
    },
  };
}
