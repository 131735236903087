import React from 'react';

// import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Company } from 'model/Company';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Data from 'components/Data/Data';
import { format } from 'date-fns';

// Props type
type Props = { company: Company };

function formatDate(timestamp?: string): string {
  if (timestamp === undefined) {
    return '---';
  }
  return format(new Date(timestamp).getTime(), 'HH:mm dd.MM.RR');
}

// Component
const OwnershipInfo = ({ company }: Props) => {
  const { t } = useTranslation('company');

  return (
    <Card>
      <CardHeader title={t('ownershipInfo.header')} />
      <CardContent>
        <Data label={t('ownershipInfo.fields.owner')} value={company.owner?.name} />
        <Data label={t('ownershipInfo.fields.email')} value={company.owner?.email} />
        <Data label={t('ownershipInfo.fields.phone')} value={company.owner?.phone} />
        <Data label={t('ownershipInfo.fields.city')} value={company.owner?.city} />
        <Data
          label={t('ownershipInfo.fields.deliveryProductType')}
          value={company.owner?.deliveryProductType}
        />
        <Data label={t('ownershipInfo.fields.createdAt')} value={formatDate(company.createdAt)} />
      </CardContent>
    </Card>
  );
};
export default OwnershipInfo;
