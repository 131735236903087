import { map, padStart } from 'lodash';
import { useTranslation } from 'app/translations/useTranslation';
import { Order } from 'pages/Orders/model/Order';
import * as Styled from '../styled';
import { WorkingHours } from 'api/OrderApi';

interface Props {
  order: Order;
}

const parseHoursToString = (workingHours: WorkingHours): string => {
  return `${padStart(workingHours.from.hour.toString(), 2, '0')}:${padStart(
    workingHours.from.minute.toString(),
    2,
    '0',
  )} - ${padStart(workingHours.to.hour.toString(), 2, '0')}:${padStart(
    workingHours.to.minute.toString(),
    2,
    '0',
  )}`;
};

export function RestSubsection(props: Props) {
  const { t } = useTranslation(['order', 'common']);

  return (
    <Styled.Subsection>
      <Styled.Header>{t('labels.rest')}:</Styled.Header>
      {props.order.packageSize !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.packageSize')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {t(`labels.packageSizes.${props.order.packageSize}`)}
          </Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {!!props.order.misc.additionalServices && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.additionalServices.label')}:</Styled.RecordLabel>
          {map(props.order.misc.additionalServices, (bool, name) =>
            bool ? (
              <Styled.RecordBlockValue>
                {t(`labels.additionalServices.${name}`)}
              </Styled.RecordBlockValue>
            ) : null,
          )}
        </Styled.RecordBox>
      )}
      {!!props.order.emergencyDelivery && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.emergencyDelivery')}:</Styled.RecordLabel>
          <Styled.RecordValue>{t('common:yes')}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.order.pickUpVerificationCode !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.pickUpPin')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.order.pickUpVerificationCode}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.order.verificationCode !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.pin')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.order.verificationCode}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {!!props.order.noVerificationCodeReason && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.noVerificationCodeReason')}:</Styled.RecordLabel>
          <Styled.CommentValue>{props.order.noVerificationCodeReason}</Styled.CommentValue>
        </Styled.RecordBox>
      )}
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.comment')}:</Styled.RecordLabel>
        <Styled.CommentValue>{props.order.comment ?? t('list.values.none')}</Styled.CommentValue>
      </Styled.RecordBox>
      {props.order.pickUpNotes && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.pickUpNotes')}:</Styled.RecordLabel>
          <Styled.CommentValue>{props.order.pickUpNotes}</Styled.CommentValue>
        </Styled.RecordBox>
      )}
      {props.order.pickupPoint?.workingHours && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.workingHours')}:</Styled.RecordLabel>
          {map(props.order.pickupPoint.workingHours, (hours, date) => (
            <Styled.RecordBlockValue key={date}>
              {hours ? (
                <Styled.RecordBlockValue>
                  {date} {parseHoursToString(hours)}
                </Styled.RecordBlockValue>
              ) : (
                t('labels.workingHoursClosed')
              )}
            </Styled.RecordBlockValue>
          ))}
        </Styled.RecordBox>
      )}
    </Styled.Subsection>
  );
}
