import { styled } from '@mui/material/styles';
import { useAuth } from 'services/authorization';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '../../../app/translations/useTranslation';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { LocationsManager } from '../../SettingsPage/components/Location/LocationsManager';
import { Location } from '../../../model/Location';
import { PrimaryButton } from '../../../components/Button/Buttons';
import { AsyncStatus } from '../../../utlis/State';
import { XDLogo, LogoVersion } from 'assets/Logo';
import * as Styled from './styled';
import { PickUpVerificationFormValues } from '../OrderPage/components/OrderList/components/Row/components/OrderRowDetails/components/ActionSection/components/PickUpVerificationAction/hooks/usePickUpVerificationForm';
import { Input } from '../../../components/ReactHookForm/Input/Input';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormProvider } from 'react-hook-form';
import {
  FindAndVerifyPickUpFormValues,
  useFindAndVerifyPickUpForm,
  useFindAndVerifyPickUpFormReset,
} from './hooks/useFindAndVerifyPickUpVerificationForm';
import { DateTimeSelect } from '../../../components/DateTimeSelect';
import { DateSelect } from '../../../components/DateSelect';
import { CreateMultiplierFormValues } from '../../MultiplierPage/components/CreateMultiplier/hooks/useCreateMultiplierForm';
import { useVerifyPickUp } from '../OrderPage/state/useVerifyPickUp';
import { Divider } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
interface Props {}

export default function VerifyPickupPage(props: Props) {
  const { t } = useTranslation('order');
  const [createdAt, setCreatedAt] = useState<Date | null>(null);
  const form = useFindAndVerifyPickUpForm();

  const {
    findAndVerifyOrderPickUp,
    findAndVerifyOrderPickUpInProgress,
    findAndVerifyOrderPickUpError,
    findAndVerifyOrderPickUpSuccess,
    findAndVerifyPickUpVerifiedOrders,
  } = useVerifyPickUp();
  const submitForm = useCallback((formValues: FindAndVerifyPickUpFormValues) => {
    findAndVerifyOrderPickUp(formValues);
  }, []);

  useEffect(() => {
    if (!findAndVerifyOrderPickUpInProgress && form.formState.isSubmitSuccessful) {
      if (findAndVerifyOrderPickUpSuccess) {
        form.reset();
        onChangeCreatedAt(new Date());
      }
      if (findAndVerifyOrderPickUpError) {
      }
    }
  }, [
    findAndVerifyOrderPickUpInProgress,
    findAndVerifyOrderPickUpSuccess,
    findAndVerifyOrderPickUpError,
  ]);

  const onChangeCreatedAt = (createdAt: Date | null) => {
    if (createdAt) {
      form.setValue('createdAt', createdAt);
      setCreatedAt(createdAt);
    }
  };

  return (
    <Container>
      <InnerContainer>
        <MainContainer>
          <HeaderContainer>
            <XDLogoContainer>
              <XDLogo version={LogoVersion.OneLine} />
            </XDLogoContainer>

            <HeadlineContainer>
              <MainHeader>{t('pickUpVerification.header')}</MainHeader>
            </HeadlineContainer>
          </HeaderContainer>
          <FormContainer>
            <FormProvider {...form}>
              <Styled.Form
                onSubmit={form.handleSubmit((formValues: FindAndVerifyPickUpFormValues) =>
                  submitForm(formValues),
                )}
                // onReset={useFindAndVerifyPickUpFormReset}
              >
                <Styled.DetailsBox>
                  <DateSelect
                    label={t('pickUpVerification.form.fields.createdAt')}
                    onChange={onChangeCreatedAt}
                    name={'createdAt'}
                    value={createdAt}
                  />
                </Styled.DetailsBox>
                <Styled.DetailsBox>
                  <Input name={'orderNo'} label={t('pickUpVerification.form.fields.orderNo')} />
                </Styled.DetailsBox>
                <Styled.DetailsBox>
                  <Input
                    name={'pin'}
                    label={t('pickUpVerification.form.fields.pin')}
                    dataCy={'input-pin'}
                  />
                </Styled.DetailsBox>
                <ActionContainer>
                  <SubmitButton
                    fullWidth
                    color={'success'}
                    variant={'outlined'}
                    disabled={findAndVerifyOrderPickUpInProgress}
                    loading={findAndVerifyOrderPickUpInProgress}
                    type="submit"
                  >
                    {t('pickUpVerification.findAndVerify.submitButton')}
                  </SubmitButton>
                </ActionContainer>
              </Styled.Form>
            </FormProvider>
          </FormContainer>
          <StyledDivider />
          {findAndVerifyPickUpVerifiedOrders?.map((verifiedOrder) => (
            <Alert
              style={{ marginBottom: '24px' }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {t('pickUpVerification.findAndVerify.verifiedOrderMessage', {
                orderNo: verifiedOrder,
              })}
            </Alert>
          ))}
        </MainContainer>
      </InnerContainer>
    </Container>
  );
}

const SuccessSubmitButton = styled(PrimaryButton)`
  margin-top: 30px;
`;

const SubmitButton = styled(PrimaryButton)`
  margin-top: 30px;
`;

const ActionContainer = styled('div')``;
const MainContainer = styled('div')`
  margin-top: 20px;
  width: 100%;
`;
const StyledDivider = styled(Divider)`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const InnerContainer = styled('div')(
  ({ theme }) => `
  width: 100%;
  padding: 20px;
  @media (min-width: 975px) {
      width: 70%;  
      padding: 60px;
  }
 
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`,
);

const Container = styled('div')(
  ({ theme }) => `
  padding: 60px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
 
  background-size: auto;
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
`,
);

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 60px;
`;
const MainHeader = styled('h1')`
  letter-spacing: 2px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
`;

const HeadlineContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const XDLogoContainer = styled('div')`
  width: 320px;
`;

const FormContainer = styled('div')`
  align-items: center;
  justify-content: center;
  display: flex;
`;
