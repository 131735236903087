import { useDispatch, useSelector } from 'react-redux';
import * as Slice from './slice';
import { AsyncStatus } from '../../../../utlis/State';

export function useVerifyPickUp() {
  const dispatch = useDispatch();
  const verifyOrderPickUpStatus = useSelector(Slice.selectors.makeSelectVerifyPickUpStatus());
  const findAndVerifyOrderPickUpStatus = useSelector(
    Slice.selectors.makeSelectFindAndVerifyPickUpStatus(),
  );

  return {
    findAndVerifyOrderPickUpInProgress: findAndVerifyOrderPickUpStatus === AsyncStatus.Pending,
    findAndVerifyOrderPickUpError: findAndVerifyOrderPickUpStatus === AsyncStatus.Error,
    findAndVerifyOrderPickUpSuccess: findAndVerifyOrderPickUpStatus === AsyncStatus.Success,
    findAndVerifyOrderPickUp(params: Slice.ActionTypes.FindAndVerifyPickUp) {
      dispatch(Slice.actions.findAndVerifyOrderPickup(params));
    },
    findAndVerifyPickUpVerifiedOrders: useSelector(
      Slice.selectors.makeSelectFindAndVerifyPickUpVerifiedOrders(),
    ),
    verifyOrderPickUpStatus,
    verifyOrderPickUp(params: Slice.ActionTypes.VerifyPickUp) {
      dispatch(Slice.actions.verifyOrderPickUp(params));
    },
  };
}
