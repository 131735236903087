import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';

export type CreateMultiplierFormValues = {
  dateFrom: Date;
  dateTo: Date;
  description?: string;
  multiplier: number;
  regions: string[];
  companyIds: string[];
  timeRange?: any[];
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object({
      dateFrom: yup.date().required(t('required')),
      dateTo: yup.date().required(t('required')),
      multiplier: yup.number().min(1.01).required(t('required')),
      regions: yup.array().min(1, t('required')).required(),
      companyIds: yup.array().min(1, t('required')).required(),
      timeRange: yup.array().of(yup.object().shape({})),
    });
  }, [t]);
}

export function useCreateMultiplierForm() {
  const validationSchema = useValidationSchema();
  return useForm<CreateMultiplierFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });
}
